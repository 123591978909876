import userScenario from './user';
import getPlatformContext from '../helpers/get-platform-context';
import { PRICING_MODEL } from 'billing-common/constants/billing';

export default function (server, options) {
  let opts = {
    // required for the consul | cluster creation for fcp org acceptance test in
    // the consul engine
    consulPackages: {
      development: true,
      standard: true,
      plus: true,
    },
    createBilling: true,
    pricingModel: PRICING_MODEL.FLEX,
    ...options,
  };

  userScenario(server, opts);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  server.create('billing-account.transition', 'flexTransition', {
    billing_account: billingAccount,
  });

  // specific contract selections not provided, default to creating
  // active contract
  if (!opts.contracts) {
    server.create('billing-account.contract', {
      organization,
      billing_account: billingAccount,
    });
  } else {
    opts.contracts.map((contract) => {
      server.create('billing-account.contract', ...contract.traits, {
        organization,
        billing_account: billingAccount,
      });
    });
  }

  server.create('billing-account.flexible-consumption-billing-method-detail', {
    billing_account: billingAccount,
  });
}
