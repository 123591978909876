import userScenario from './user';
import { contributorPermissions } from '../helpers/permissions';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

const supportedVcsEvents = [
  'viewed',
  'listed',
  'created',
  'updated',
  'deleted',
];

export default function (server, options = {}) {
  userScenario(server, {
    // billing
    createBilling: true,
    hasVerifiedEmail: true,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.ACTIVE,
    onDemandBillingMethodDetails: true,
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,

    // misc
    createConsul: false,
    createVault: false,
    allowedPermissions: contributorPermissions,
    ...options,
  });

  const firstOrg = server.schema.first('resourceManager.organization');
  const firstProj = server.schema.first('resourceManager.project');

  // create sync integrations
  const awsOne = server.create('secrets.sync-integration', 'aws-sm', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    name: 'primary-aws-sync',
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
  });

  const ghaOne = server.create('secrets.sync-integration', 'gh-repo', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    name: 'gh-repo-test-gh-org-fish',
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
    gh_repo_connection_details: {
      owner: 'test-gh-org',
      environment: 'production',
      repository: 'fish',
    },
  });

  const vercelOne = server.create(
    'secrets.sync-integration',
    'vercel-project',
    {
      $orgId: firstOrg.id,
      $projId: firstProj.id,
      name: 'vercel-test-project',
      location: { organization_id: firstOrg.id, project_id: firstProj.id },
    }
  );

  const azureKvOne = server.create('secrets.sync-integration', 'azure-kv', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    name: 'primary-azure-sync',
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
  });

  // create rotation integrations
  const twilioIntegration = server.create('secrets.integration', 'twilio', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
  });

  const mongoIntegration = server.create(
    'secrets.integration',
    'mongodb-atlas',
    {
      $orgId: firstOrg.id,
      $projId: firstProj.id,
    }
  );

  server.createList('secrets.app', 3).forEach((app, i) => {
    const { id, location, name } = app;

    switch (name) {
      // app-0 will have syncs
      case 'app-0':
        app.update({
          sync_integrations: [
            awsOne.name,
            azureKvOne.name,
            ghaOne.name,
            vercelOne.name,
          ],
        });

        server
          .createList(
            'secrets.app-secret',
            Math.floor(Math.random() * 10) + 3,
            'static.kv',
            {
              app,
            }
          )
          .forEach((secret) => {
            // each secret needs to have a status for each integration
            [awsOne, azureKvOne, ghaOne, vercelOne].forEach((integration) => {
              server.create('secrets.app-secret.sync-event', {
                status:
                  integration.name === awsOne.name ? 'IN_ERROR' : 'SYNCED',
                last_error_code: 'IntegrationUnauthorized',
                updated_at: Date.now(),

                secret,
                syncIntegration: integration,

                $appName: name,
                $syncName: integration.name,
                $orgId: location.organization_id,
                $projId: location.project_id,
              });
            });
          });

        break;

      // app-1 will have rotating secrets
      case 'app-1':
        server.create('secrets.app-secret', 'rotating.twilio', {
          app,
          name: 'APP_1_TWILIO_STAGING',
          integration_name: twilioIntegration.name,
          $rotationPolicyName: 'built-in:90-days-2-active',
        });

        server.create('secrets.app-secret', 'rotating.mongodb-atlas', {
          app,
          name: `APP_1_DB_PROD`,
          integration_name: mongoIntegration.name,
          $rotationPolicyName: 'built-in:60-days-2-active',
        });

        // add an empty secret for the sake of testing
        // eslint-disable-next-line no-case-declarations
        const emptySecret = server.create('secrets.app-secret', 'empty', {
          app,
          name: 'empty_rotating_secret',
          $status: 'ROTATION_STARTING',
        });

        setTimeout(() => {
          emptySecret.update({ $status: 'ERRORED' });
        }, 10000);

        // create some extra kv secrets so the page looks nicer
        server.createList('secrets.app-secret', 1, 'static.kv', { app });
        break;
      default:
        server.createList(
          'secrets.app-secret',
          Math.floor(Math.random() * 10) + 3,
          'static.kv',
          {
            app,
          }
        );
    }

    for (const e of supportedVcsEvents) {
      server.createList('log.platform-event', 3, 'secret', `secrets.${e}`, {
        stream: {
          organization_id: location.organization_id,
          project_id: location.project_id,
          topic: 'hashicorp.platform.audit',
        },
        control_plane_event: {
          metadata: {
            app_name: name,
            app_id: id,
          },
        },
      });
    }
  });
}
