import { faker } from '@faker-js/faker';
import userScenario from './user';
import { PRICING_MODEL } from '../consts/billing';
import getPlatformContext from '../helpers/get-platform-context';

export default function (server, options) {
  let opts = {
    createBilling: true,
    pricingModel: PRICING_MODEL.ENTITLEMENT,
    ...options,
  };

  userScenario(server, opts);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  server.create('billing-account.entitlement-billing-method-detail', {
    billing_account: billingAccount,
    billing_method: {
      reference: faker.uuid,
      created_at: faker.date.past().toISOString(),
    },
  });

  server.create('billing-account.transition', 'entitlementTransition', {
    billing_account: billingAccount,
  });
}
