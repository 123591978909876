// Prefixes
export const PREFIX_BILLING_ACCOUNTS = 'billing.accounts';
export const PREFIX_CONSUL_CLUSTERS = 'consul.clusters';
export const PREFIX_CONSUL_SNAPSHOTS = 'consul.snapshots';
export const PREFIX_CONSUL_GNM_CLUSTERS =
  'global-network-manager.consul-clusters';
export const PREFIX_CONSUL_GNM_OBSERVABILITY =
  'global-network-manager.consul-observability';
export const PREFIX_CONSUL_GNM_PEERING_CONNECTIONS =
  'global-network-manager.peering-connections';
export const PREFIX_CONSUL_GNM_CLUSTERS_SERVERS =
  'global-network-manager.consul-clusters-servers';
export const PREFIX_IAM_GROUPS = 'iam.groups';
export const PREFIX_IAM_INVITATIONS = 'iam.invitations';
export const PREFIX_IAM_USERS = 'iam.users';
export const PREFIX_IAM_SERVICE_PRINCIPALS = 'iam.service-principals';
export const PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS =
  'iam.workload-identity-providers';
export const PREFIX_IAM_SSO = 'iam.sso-configurations';
export const PREFIX_IAM_AUTH_CONNECTION = 'iam.auth-connection';
export const PREFIX_IDP_SCIM = 'idp.scim';
export const PREFIX_NETWORK_DEPENDENCIES = 'network.dependencies';
export const PREFIX_NETWORK_HVNS = 'network.hvns';
export const PREFIX_NETWORK_ROUTES = 'network.routes';
export const PREFIX_NETWORK_PEERINGS = 'network.peerings';
export const PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS =
  'network.tgw-attachments';
export const PREFIX_OPERATION_OPERATIONS = 'operation.operations';
export const PREFIX_RESOURCE_MANAGER_ORGANIZATIONS =
  'resource-manager.organizations';
export const PREFIX_RESOURCE_MANAGER_PROJECTS = 'resource-manager.projects';
export const PREFIX_RESOURCE_MANAGER_RESOURCES = 'resource-manager.resources';
export const PREFIX_RESOURCE_MANAGER_ROLES = 'resource-manager.roles';
export const PREFIX_TERRAFORM_WORKSPACES = 'terraform.workspaces';
export const PREFIX_LOG_ENTRIES = 'log.entries';
export const PREFIX_PACKER_CHANNELS = 'packer.channels';
export const PREFIX_PACKER_BUCKETS = 'packer.bucket';
export const PREFIX_PACKER_VERSIONS = 'packer.versions';
export const PREFIX_PACKER_REGISTRIES = 'packer.registries';
export const PREFIX_BOUNDARY_CLUSTERS = 'boundary.clusters';
export const PREFIX_VAGRANT_REGISTRY = 'vagrant.registry';
export const PREFIX_WEBHOOK_WEBHOOKS = 'webhook.webhooks';

// Actions
export const ACTION_ASSIGN_PROJECT = 'assign-project';
export const ACTION_CREATE = 'create';
export const ACTION_CREATE_ADMIN_TOKEN = 'create-admin-token';
export const ACTION_CREATE_ROOT_TOKEN = 'create-root-token';
export const ACTION_CREATE_TOKEN = 'create-token';
export const ACTION_DELETE = 'delete';
export const ACTION_DELETE_TOKEN = 'delete-token';
export const ACTION_DISABLE = 'disable';
export const ACTION_ENABLE = 'enable';
export const ACTION_FORCE = 'force';
export const ACTION_GET = 'get';
export const ACTION_GET_CLIENT_CONFIG = 'get-client-config';
export const ACTION_GET_IAM_POLICY = 'get-iam-policy';
export const ACTION_GET_API_INFORMATION = 'get-api-information';
export const ACTION_GET_SECRETS = 'get-secrets';
export const ACTION_GET_CREDENTIALS = 'get-credentials';
export const ACTION_LIST = 'list';
export const ACTION_LIST_ROLES = 'list-roles';
export const ACTION_LIST_TOKENS = 'list-tokens';
export const ACTION_LIST_VERSIONS = 'list-versions';
export const ACTION_OPEN = 'open';
export const ACTION_READ = 'read';
export const ACTION_SET_IAM_POLICY = 'set-iam-policy';
export const ACTION_SET_NAME = 'set-name';
export const ACTION_STORE = 'store';
export const ACTION_TOKENIZE_PAYMENT_DETAILS = 'tokenize-payment-details';
export const ACTION_UNASSIGN_PROJECT = 'unassign-project';
export const ACTION_UPDATE = 'update';
export const ACTION_RUN = 'run';
export const ACTION_UPDATE_MEMBERS = 'update-members';
export const ACTION_LIST_MEMBERS = 'list-members';
