import { inject as service } from '@ember/service';
import BaseAbility from './base-ability';
import { TYPE_ORGANIZATION } from 'common/utils/cloud-resource-types';
import {
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
  ACTION_GET_IAM_POLICY,
  ACTION_SET_IAM_POLICY,
  ACTION_LIST_ROLES,
  ACTION_SET_NAME,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_RESOURCE_MANAGER_ORGANIZATIONS;

  @service quota;
  @service userContext;

  /**
   * The ability checks both the LaunchDarkly flag but also ensures that the
   * user has not already created an organization.
   *
   * @param {Array} model - a list of organizations.
   * @param {String} params.userId - the userId of the user to check against.
   * @param {Boolean} params.isSsoUser - the sso sign in status of the user
   * @returns {Boolean}
   */
  get canCreate() {
    let model = this.model || [];
    let ownedOrganizations = model.filter(
      (m) => m?.owner?.user === this.userId,
    );

    return (
      ownedOrganizations.length < this.quota.for(TYPE_ORGANIZATION) &&
      !this.isSsoUser
    );
  }

  get canGetIamPolicy() {
    return this.permissions.scopedPermissions?.organization?.includes(
      this.generate(ACTION_GET_IAM_POLICY),
    );
  }

  get canListRoles() {
    return this.permissions.scopedPermissions?.organization?.includes(
      this.generate(ACTION_LIST_ROLES),
    );
  }

  get canRename() {
    let { unifiedExperienceEnabled } = this.userContext.organizationMeta;
    return (
      this.permissions.scopedPermissions?.organization?.includes(
        this.generate(ACTION_SET_NAME),
      ) && !unifiedExperienceEnabled
    );
  }

  get canSetIamPolicy() {
    return this.permissions.scopedPermissions?.organization?.includes(
      this.generate(ACTION_SET_IAM_POLICY),
    );
  }

  get canViewRoleAssignments() {
    return this.canGetIamPolicy && this.canListRoles;
  }
}
