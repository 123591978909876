import { faker } from '@faker-js/faker';
import {
  TYPE_CONSUL_GLOBAL_NETWORK_MANAGER,
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
  TYPE_STREAMING_DESTINATION,
  TYPE_VAULT_RADAR,
} from 'common/utils/cloud-resource-types';

import {
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_CONTRIBUTOR,
  ROLE_TYPE_OWNER,
  ROLE_TYPE_VIEWER,
  ROLE_TYPE_SECRETS_READER,
  ROLE_TYPE_SECRETS_MANAGER,
} from 'core/helpers/rbac-roles';
import {
  ACTION_LIST,
  PREFIX_CONSUL_CLUSTERS,
  PREFIX_IAM_INVITATIONS,
  PREFIX_IAM_SERVICE_PRINCIPALS,
  PREFIX_IAM_USERS,
  PREFIX_NETWORK_HVNS,
  PREFIX_NETWORK_PEERINGS,
  PREFIX_NETWORK_ROUTES,
  PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS,
  PREFIX_VAULT_CLUSTERS,
} from 'authz/utils/permission-types/index';

import statuspageIo from 'core/utils/statuspage-io';
import { MarketingEmailStatuses } from 'core/utils/marketing-email';
import { STATUSPAGE_IO_STATUS } from 'core/utils/constants';
import {
  HashicorpCloudGlobalNetworkManager20220215TrialStatus,
  HashicorpCloudGlobalNetworkManager20220215Licensing,
} from '@clients/cloud-global-network-manager-service';

import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';
import { PRICING_MODEL } from '../consts/billing';

import { LogService20210330Status } from '@clients/cloud-log-service';

import { slugIdAttrs } from '../helpers/slug-id';
import createPermissions, {
  fullPermissions,
  createProjectPermissions,
} from '../helpers/permissions';
import { createVaultPackages } from '../helpers/create-vault-packages';

import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';
const { USER } = HashicorpCloudResourcemanagerPolicyBindingMemberType;

// creates a single user and web-console-prefs
export default function (
  server,
  options = {
    systemStatus: {
      hcp: STATUSPAGE_IO_STATUS.OPERATIONAL,
      consul: STATUSPAGE_IO_STATUS.OPERATIONAL,
      packer: STATUSPAGE_IO_STATUS.OPERATIONAL,
      vault: STATUSPAGE_IO_STATUS.OPERATIONAL,
    },
    marketingEmailStatus: MarketingEmailStatuses.OPTIONAL,
    createConsul: true,
    createVaultRadar: false,
    numConsulConnectedClients: 0,
    createConsulAuditLogs: true,
    createOrgStreamingDestinations: true,
    createConsulPlatformLogs: true,
    consulPackages: {
      development: true,
      standard: true,
      plus: true,
      premium: true,
    },
    consulPrimaryFederatedCluster: false,
    consulSecondaryFederatedCluster: false,
    consulReadOnlyCluster: true,
    createAzureNetwork: true,
    createAzurePeering: {
      state: 'PENDING_ACCEPTANCE',
      azureTarget: false,
      hubSpoke: {
        useGateway: true,
        allowForward: true,
      },
    },
    // this is used to configure aws network
    createNetwork: true,
    // peering on aws network
    createPeeringConnection: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: true,
    },
    createTgwAttachment: {
      state: 'PENDING_ACCEPTANCE',
      hvnRoutes: false,
    },
    // this defines what clusters exist when you boot the app
    createVault: {
      development: true,
      standard: false,
      starter: true,
      plus: false,
    },
    // begin billing
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.UNSET,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.TRIAL,
    createBilling: true,
    hasExpiredCreditCard: false,
    onDemandBillingMethodDetails: true,
    // both Trial and onDemand accounts should return a PAYG pricing model
    pricingModel: PRICING_MODEL.PAYG,
    // end billing
    createServicePrincipals: {
      organization: true,
      project: true,
    },
    createGroups: true,
    samlSsoConfigured: false,
    hashicorpSsoConfigured: false,
    scimEnabled: false,
    hasNoOwnedOrg: false,
    hasMultipleOrgMemberships: true,
    hasVerifiedEmail: true,
    // this defines what vault tier packages are available
    vaultPackages: {
      development: true,
      standard: true,
      starter: true,
      plus: true,
    },
    allowedPermissions: fullPermissions,
    hvsBillingTier: 'FREE',
  }
) {
  if (options?.marketingEmailStatus) {
    server.create('marketing-email.status', {
      status: `${options.marketingEmailStatus}`,
    });
  }

  if (options?.systemStatus) {
    // NOTE: Unused properties omitted from the Mirage `statuspage-io.component` schema.
    if (options?.systemStatus?.hcp) {
      server.create('statuspage-io.component', {
        id: statuspageIo.NON_PRODUCTION.hcpComponentIds[0],
        status: options.systemStatus.hcp,
      });
    }

    if (options?.systemStatus?.consul) {
      server.create('statuspage-io.component', {
        id: statuspageIo.NON_PRODUCTION.consulComponentIds[0],
        status: options.systemStatus.consul,
      });
    }

    if (options?.systemStatus?.packer) {
      server.create('statuspage-io.component', {
        id: statuspageIo.NON_PRODUCTION.packerComponentIds[0],
        status: options.systemStatus.packer,
      });
    }

    if (options?.systemStatus?.vault) {
      server.create('statuspage-io.component', {
        id: statuspageIo.NON_PRODUCTION.vaultComponentIds[0],
        status: options.systemStatus.vault,
      });
    }
  }

  if (!options.createBilling) {
    console.warn(`
      [user scenario]: createBilling is disabled and this may cause undesireable
      side effects. The application requires billing to be available at the root
      Only disable billing when you explicitly want to test the app without billing.
    `);
  }

  let user = server.create('iam.user-principal', {
    current_user: true,
    tfc_synchronized_at: new Date().toISOString(),
  });

  //create contacts for email verification
  let verifiedAt = options.hasVerifiedEmail ? '2020-10-01T22:06:17.324Z' : null;

  server.create('email.contact', {
    source_type: 'HCP_USER_PRINCIPAL',
    verified_at: verifiedAt,
    email_address: user.email,
    source_identifier: user.id,
  });

  server.create('web-console-preference');

  let userPrincipalOne = server.create('iam.user-principal', {
    created_at: '2020-09-01T22:06:17.324Z',
    current_user: false,
    email: 'jgordon@hashicorp.com',
    full_name: 'Jim Gordon',
    id: '16cff5e5-8o88-4275-85fb-b8cb856717fx',
    scim_synchronized: false,
    updated_at: '2020-10-01T22:06:17.324Z',
  });

  let userSecrets = server.create('iam.user-principal', {
    created_at: '2020-09-01T22:06:17.324Z',
    current_user: false,
    email: 'secretsquirrel@hashicorp.com',
    full_name: 'Secret Squirrel',
    id: '13cff5e5-8o88-4275-85fb-b8cb856717fx',
    scim_synchronized: false,
    updated_at: '2022-10-01T22:06:17.324Z',
  });

  let userPrincipalTwo = server.create('iam.user-principal', {
    created_at: '2020-09-01T22:06:17.324Z',
    current_user: false,
    email: 'enigma@hashicorp.com',
    full_name: 'Edward Nigma',
    id: '15cff5e5-8o88-4275-85fb-b8cb856717fx',
    scim_synchronized: false,
    updated_at: '2020-10-01T22:06:17.324Z',
  });
  let noRoleUserPrincipal = server.create('iam.user-principal', {
    created_at: '2020-09-01T22:06:17.324Z',
    current_user: false,
    email: 'sarah.lee@hashicorp.com',
    full_name: 'Sarah Lee',
    id: '15cff5e5-8o88-4275-85fb-b8cb856717d',
    scim_synchronized: false,
    updated_at: '2020-10-01T22:06:17.324Z',
  });

  // always seed with the same IDs so that page refreshes work
  let org = server.create('resource-manager.organization', {
    id: '4988ada2-cb2c-4ca5-9e44-e129da0e1cb8',
    name: 'default-org',
    owner: options.hasNoOwnedOrg ? userPrincipalTwo : user,
    tfc_synced: false,
  });

  server.create('sso.preconfiguration', {
    organization: org,
    assertion_consumer_url: 'https://oktasignin.local',
    domain_txt_verification_record:
      'hcp-domain-verification=6ea52e476fc6232d974c31453dcd884a68df6cf374892a50a897c87d11125b67',
    entity_id: 'abc-123',
  });

  let project = server.create('resource-manager.project', {
    id: '97cff5f5-8c88-4275-85fb-b8ad856717cf',
    organization: org,
    parent: {
      type: 'ORGANIZATION',
      id: org.id,
    },
    description: 'An initial project',
  });

  let org2 = server.create('resource-manager.organization', {
    id: '5988ada2-cb2c-4cd8-9e44-e129da0e1cb8',
    name: 'org-the-second',
    owner: userPrincipalTwo,
    tfc_synced: false,
  });

  server.create('resource-manager.project', {
    id: '88cff5f5-8c88-4275-85fb-b8ad856717cf',
    organization: org2,
    parent: {
      type: 'ORGANIZATION',
      id: org2.id,
    },
    description: 'And another one...',
  });

  const org2policy = {
    bindings: [
      {
        role_id: ROLE_TYPE_OWNER.value,
        members: [
          {
            member_type: USER,
            member_id: userPrincipalTwo.id,
          },
        ],
      },
      {
        role_id: ROLE_TYPE_ADMIN.value,
        members: [
          {
            member_type: USER,
            member_id: userPrincipalOne.id,
          },
          {
            member_type: USER,
            member_id: user.id,
          },
        ],
      },
      {
        role_id: ROLE_TYPE_CONTRIBUTOR.value,
        members: [
          {
            member_type: 'USER',
            member_id: userPrincipalTwo.id,
          },
        ],
      },
      {
        role_id: ROLE_TYPE_VIEWER.value,
        members: [],
      },
    ],
    etag: faker.string.alphanumeric(51),
  };

  server.create('resource-manager.organization.policy', {
    organization: org2,
    policy: org2policy,
  });

  // Invitations - add org for accepting an invite.
  // Visit http://localhost:4200/accept-invitation/19cff5e5-8o88-4275-85fb-b8xb856717fx?org_name=Invitation%20Org
  let org3 = server.create('resource-manager.organization', {
    id: '4988ada2-cb2c-4ca5-9e44-e129da0e1cb9',
    name: 'invitation-org',
    owner: userPrincipalTwo,
    tfc_synced: false,
  });

  let org4 = server.create('resource-manager.organization', {
    id: '36716818-6bd0-4b4c-b766-171b2ac1fee2',
    name: 'new-org',
    owner: userPrincipalTwo,
    tfc_synced: false,
  });

  server.create('resource-manager.project', {
    id: '8db4dbcf-af5e-450d-848d-553a13df9a70',
    organization: org4,
    parent: {
      type: 'ORGANIZATION',
      id: org4.id,
    },
    description: 'New org project',
  });

  server.create('iam.organization-membership', {
    created_at: '2020-09-01T22:06:17.324Z',
    organization: org2,
    principal: userPrincipalTwo,
  });

  let region = server.create('location.region', {
    region: 'us-west-2',
    provider: 'aws',
  });

  let loc = server.create('location.location', {
    organizationId: org.id,
    projectId: project.id,
    regionId: region.id,
  });

  // Organization Invitations
  server.create('invitation.invitation', {
    accepted_at: null,
    created_at: '2020-07-01T22:29:11.974114Z',
    id: '18cff5e5-8o88-4275-85fb-b8cb856717fx',
    invitee_email: 'dgrayson@hashicorp.com',
    inviterUserPrincipal: user,
    organization: org,
    role: 'roles/admin',
    state: 'PENDING',
    valid_until: '2020-07-01T22:29:11.974114Z', // Invalid.
  });

  server.create('invitation.invitation', {
    accepted_at: null,
    created_at: '2020-07-01T22:29:11.974114Z',
    id: '17cff5e5-8o88-4275-85fb-b8cb856717fx',
    invitee_email: 'bwayne@hashicorp.com',
    inviterUserPrincipal: user,
    organization: org,
    role: 'roles/contributor',
    state: 'PENDING',
    valid_until: '3020-07-01T22:29:11.974114Z', // Valid for ~1000 years.
  });

  server.create('invitation.invitation', {
    accepted_at: '2020-07-01T22:29:11.974114Z',
    created_at: '2020-07-01T22:29:11.974114Z',
    id: '16cff5e5-8o88-4275-85fb-b8cb856717fx',
    invitee_email: 'apennyworth@hashicorp.com',
    inviterUserPrincipal: user,
    organization: org,
    role: 'roles/admin',
    state: 'ACCEPTED',
    valid_until: '2020-07-01T22:29:11.974114Z', // Valid for ~100 years.
  });

  server.create('iam.organization-membership', {
    created_at: '2020-09-01T22:06:17.324Z',
    organization: org,
    principal: userPrincipalOne,
  });

  server.create('iam.organization-membership', {
    created_at: '2020-09-01T22:06:17.324Z',
    organization: org,
    principal: userPrincipalTwo,
  });
  server.create('iam.organization-membership', {
    created_at: '2020-09-01T22:06:17.324Z',
    organization: org,
    principal: noRoleUserPrincipal,
  });
  server.create('iam.organization-membership', {
    created_at: '2023-09-01T22:06:17.324Z',
    organization: org,
    principal: userSecrets,
  });

  const organizationPolicy = {
    bindings: [
      {
        role_id: ROLE_TYPE_OWNER.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_ADMIN.value,
        members: [
          {
            member_type: 'USER',
            member_id: userPrincipalOne.id,
          },
        ],
      },
      {
        role_id: ROLE_TYPE_CONTRIBUTOR.value,
        members: [
          {
            member_type: 'USER',
            member_id: userPrincipalTwo.id,
          },
        ],
      },
      {
        role_id: ROLE_TYPE_VIEWER.value,
        members: [],
      },
    ],
    etag: faker.string.alphanumeric(51),
  };

  if (!options.hasNoOwnedOrg) {
    // update org ownership
    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_OWNER.value)
      .members.push({
        member_type: 'USER',
        member_id: user.id,
      });
    org.update({
      owner: user,
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org,
      principal: user,
    });
  }

  if (options.hasMultipleOrgMemberships) {
    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org2,
      principal: user,
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org4,
      principal: user,
    });
  }

  let orgServicePrincipalOne = null;
  let orgServicePrincipalTwo = null;
  if (options.createServicePrincipals?.organization) {
    orgServicePrincipalOne = server.create(
      'service-principal.service-principal',
      {
        id: `admin-role-org-policy-with-key-123456@${org.id}`,
        name: 'admin-role-org-policy-with-keys',
        organizationId: org.id,
        created_at: '2020-07-01T22:29:11.974114Z',
        projectId: '',
        resourceName: `iam/organization/${org.id}/service-principal/admin-role-org-policy-with-keys`,
      }
    );

    orgServicePrincipalTwo = server.create(
      'service-principal.service-principal',
      {
        id: `contributor-role-org-policy-no-keys-567890@${org.id}`,
        name: 'contributor-role-org-policy-no-keys',
        organizationId: org.id,
        created_at: '2020-07-02T22:29:11.974114Z',
        projectId: '',
        resourceName: `iam/organization/${org.id}/service-principal/contributor-role-org-policy-no-keys`,
      }
    );

    // This organization level service principal will intentionally not be on the IAM policy to test
    // the scenario where it fails to get added to the policy.
    server.create('service-principal.service-principal', {
      id: `no-role-org-policy-543210@${org.id}`,
      name: 'no-role-org-policy',
      organizationId: org.id,
      created_at: '2020-07-03T22:29:11.974114Z',
      projectId: '',
      resourceName: `iam/organization/${org.id}/service-principal/no-role-org-policy`,
    });

    server.create('service-principal.service-principal-key', {
      clientId: '6963068b-c66b-4399-8e22-43219473437d',
      createdAt: '2020-07-01T22:30:11.974114Z',
      state: 'ACTIVE',
      principalId: orgServicePrincipalOne.id,
    });

    server.create('service-principal.service-principal-key', {
      clientId: '99f36c2c-60c3-4b7e-bd0d-1f8c41dfb028',
      createdAt: '2020-07-11T22:30:11.974114Z',
      state: 'ACTIVE',
      principalId: orgServicePrincipalOne.id,
    });

    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_ADMIN.value)
      .members.push({
        member_type: 'SERVICE_PRINCIPAL',
        member_id: orgServicePrincipalOne.id,
      });

    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_CONTRIBUTOR.value)
      .members.push({
        member_type: 'SERVICE_PRINCIPAL',
        member_id: orgServicePrincipalTwo.id,
      });
  }

  const projectPolicy = {
    bindings: [
      {
        role_id: ROLE_TYPE_OWNER.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_ADMIN.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_CONTRIBUTOR.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_SECRETS_READER.value,
        members: [],
      },
      {
        role_id: ROLE_TYPE_SECRETS_MANAGER.value,
        members: [],
      },
    ],
    etag: faker.string.alphanumeric(51),
  };

  projectPolicy.bindings
    .find((b) => b.role_id === ROLE_TYPE_SECRETS_MANAGER.value)
    .members.push({
      member_type: 'USER',
      member_id: userSecrets.id,
    });

  let projectServicePrincipalOne = null;
  let projectServicePrincipalTwo = null;
  if (options.createServicePrincipals?.project) {
    projectServicePrincipalOne = server.create(
      'service-principal.service-principal',
      {
        id: `admin-role-project-policy-345678@${project.id}`,
        name: 'admin-role-project-policy',
        organizationId: org.id,
        createdAt: faker.date.past().toISOString(),
        projectId: project.id,
        resourceName: `iam/project/${project.id}/service-principal/admin-role-project-policy`,
      }
    );

    projectServicePrincipalTwo = server.create(
      'service-principal.service-principal',
      {
        id: `contributor-role-project-policy-246802@${project.id}`,
        name: 'contributor-role-project-policy',
        organizationId: org.id,
        createdAt: faker.date.past().toISOString(),
        projectId: project.id,
        resourceName: `iam/project/${project.id}/service-principal/contributor-role-project-policy`,
      }
    );

    // This project level service principal will intentionally not be on the IAM policy to test
    // the scenario where it fails to get added to the policy.
    server.create('service-principal.service-principal', {
      id: `no-role-project-policy-112358@${project.id}`,
      name: 'no-role-project-policy',
      organizationId: org.id,
      createdAt: faker.date.past().toISOString(),
      projectId: project.id,
      resourceName: `iam/project/${project.id}/service-principal/no-role-project-policy`,
    });

    server.create('service-principal.service-principal-key', {
      clientId: '2xefdc6cCcEC6350E9b44e85e3Ff7F7DE9',
      createdAt: '2021-08-04T22:30:11.974114Z',
      state: 'ACTIVE',
      principalId: projectServicePrincipalOne.id,
    });

    server.create('service-principal.service-principal-key', {
      clientId: '0xDd1bAb25a9267Cf5Ddc1DAadfcAa8393',
      createdAt: '2022-11-11T22:55:38.974114Z',
      state: 'ACTIVE',
      principalId: projectServicePrincipalOne.id,
    });

    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_ADMIN.value)
      .members.push({
        member_type: 'SERVICE_PRINCIPAL',
        member_id: projectServicePrincipalOne.id,
      });

    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_CONTRIBUTOR.value)
      .members.push({
        member_type: 'SERVICE_PRINCIPAL',
        member_id: projectServicePrincipalTwo.id,
      });
    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_SECRETS_READER.value)
      .members.push({
        member_type: 'SERVICE_PRINCIPAL',
        member_id: projectServicePrincipalTwo.id,
      });
  }

  if (options.samlSsoConfigured) {
    let organization = server.schema.first('resource-manager.organization');

    let createdAt = new Date();

    server.create('sso.configuration', {
      organization,
      saml: {
        assertion_consumer_url: 'https://oktasignin.local',
        certificate: 'this should be a certificate',
        email_domains: ['mysite.local', 'oursite.local'],
        entity_id: 'abc-123',
        signin_url: 'signmein.local',
      },
      createdAt: createdAt,
      updatedAt: createdAt,
      type: 'SAML',
      default_role: 'roles/admin',
    });

    // update current user's identity types
    user.update({
      identity_types: ['SAMLP'],
    });

    // make saml sso org tfc_synced
    organization.update({
      tfc_synced: true,
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org,
      principal: user,
    });
  }
  if (options.hashicorpSsoConfigured) {
    let organization = server.schema.first('resource-manager.organization');

    let createdAt = new Date();

    server.create('sso.configuration', {
      organization,
      saml: {
        assertion_consumer_url: 'https://microsoftsignin.local',
        certificate: 'this should be a certificate',
        email_domains: ['mysite.local', 'oursite.local'],
        entity_id: 'abc-123',
        signin_url: 'signmein.local',
      },
      createdAt: createdAt,
      updatedAt: createdAt,
      type: 'OIDC',
      default_role: 'roles/viewer',
    });

    // update current user's identity types...
    user.update({
      identity_types: ['HASHICORP_SSO'],
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org,
      principal: user,
    });
  }

  server.create('idp.scim', {
    $organization: org,
    is_enabled: !!options?.scimEnabled,
  });

  if (options.scimEnabled) {
    const scim = server.schema.findBy(
      'idp.scim',
      (scim) => scim.$organizationId === org.id
    );
    scim.update({ is_enabled: true });

    let randString = faker.string.alpha({ length: { min: 90, max: 115 } });
    const token = {
      $organization_id: org.id,
      token_id: `hcp_sp_token_${randString}`,
      display_name: 'initial token',
      created_at: faker.date.past().toISOString(),
      last_used_at: faker.date.recent().toISOString(),
    };
    server.create('idp.scim.token', token);

    randString = faker.string.alpha(115);
    const token2 = {
      $organization_id: org.id,
      token_id: `hcp_sp_token_${randString}`,
      display_name: 'scim token 2',
      created_at: faker.date.past().toISOString(),
      last_used_at: faker.date.recent().toISOString(),
    };
    server.create('idp.scim.token', token2);
  }

  if (options.createGroups) {
    server.create('iam.group', {
      display_name: 'My empty group no policy',
      resource_name: `iam/organization/${org.id}/group/my-empty-group-no-policy`,
      resource_id: 'iam.group:Mr7cKwqGmhwpQD8MLgRt',
      description:
        'Floating group, without any members, and no project level IAM policy role assignment. A rolling stone gathers no moss.',
      scim_synchronized: false,
      created_at: faker.date.past().toISOString(),
      updated_at: new Date().toISOString(),
    });

    let member_principal_ids = [user.id, userPrincipalOne.id];
    if (options.createServicePrincipals?.organization) {
      member_principal_ids.push(orgServicePrincipalOne.id);
      member_principal_ids.push(orgServicePrincipalTwo.id);
    }
    if (options.createServicePrincipals?.project) {
      member_principal_ids.push(projectServicePrincipalOne.id);
      member_principal_ids.push(projectServicePrincipalTwo.id);
    }

    server.create('iam.group', {
      display_name: 'My group with members no policy',
      resource_name: `iam/organization/${org.id}/group/my-group-with-members-no-policy`,
      resource_id: 'iam.group:tRgLM8DQpwhmGqwKc7r5',
      description:
        'Floating group, with two members, and no project level IAM policy role assignment. Nice to have some friends.',
      member_principal_ids,
      scim_synchronized: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    });

    const emptyGroupProjectContributor = server.create('iam.group', {
      display_name: 'My empty group with project contributor role',
      resource_name: `iam/organization/${org.id}/group/my-empty-group-project-contributor`,
      resource_id: 'iam.group:wq66d6GmhwpQD8MRtSm',
      description:
        'Floating group, without any members, has contributor role in project level IAM policy binding.',
      scim_synchronized: false,
      created_at: faker.date.past().toISOString(),
      updated_at: new Date().toISOString(),
    });
    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_CONTRIBUTOR.value)
      .members.push({
        member_type: 'GROUP',
        member_id: emptyGroupProjectContributor.resource_id,
      });
    const secretsManagerGroup = server.create('iam.group', {
      display_name: 'My group with secrets manager role',
      resource_name: `iam/organization/${org.id}/group/secrets-manager-group`,
      resource_id: 'iam.group:wq26d6GmhwpQD8MRtSm',
      description:
        'Group with secrets manager role at project level and contributor role',
      scim_synchronized: false,
      created_at: faker.date.past().toISOString(),
      updated_at: new Date().toISOString(),
    });
    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_SECRETS_MANAGER.value)
      .members.push({
        member_type: 'GROUP',
        member_id: secretsManagerGroup.resource_id,
      });
    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_CONTRIBUTOR.value)
      .members.push({
        member_type: 'GROUP',
        member_id: secretsManagerGroup.resource_id,
      });
    const secretsReaderGroup = server.create('iam.group', {
      display_name: 'My group with secrets reader role',
      resource_name: `iam/organization/${org.id}/group/secrets-reader-group`,
      resource_id: 'iam.group:wq36d6GmhwpQD8MRtSm',
      description: 'Group with secrets reader role at project level',
      scim_synchronized: false,
      created_at: faker.date.past().toISOString(),
      updated_at: new Date().toISOString(),
    });
    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_SECRETS_READER.value)
      .members.push({
        member_type: 'GROUP',
        member_id: secretsReaderGroup.resource_id,
      });
    let groupAdminResourceName = `iam/organization/${org.id}/group/group-with-members-project-admin`;
    const groupWithMembersProjectAdmin = server.create('iam.group', {
      $organization_id: org.id,
      groupAdminResourceName,
      display_name: 'My group with members and project admin role',
      resource_name: groupAdminResourceName,
      resource_id: 'iam.group:5a55wqdGmhwpBb8MRtXx',
      description:
        'Group with members, has admin role in project level IAM policy binding.',
      member_principal_ids,
      scim_synchronized: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    });

    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_ADMIN.value)
      .members.push({
        member_type: 'GROUP',
        member_id: groupWithMembersProjectAdmin.resource_id,
      });

    // scim synchronized groups with scim synchronized users
    let userPrincipalThree = server.create('iam.user-principal', {
      created_at: '2020-09-01T22:06:17.324Z',
      current_user: false,
      email: 'scimimported@hashicorp.com',
      full_name: 'Kyla Scim',
      id: '18dfe5e5-8o88-4275-85fb-b8cb856717fx',
      scim_synchronized: true,
      identity_types: ['HASHICORP_SSO'],
      updated_at: '2020-10-01T22:06:17.324Z',
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org,
      principal: userPrincipalThree,
    });

    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_VIEWER.value)
      .members.push({ member_type: 'USER', member_id: userPrincipalThree.id });

    let userPrincipalFour = server.create('iam.user-principal', {
      created_at: '2020-09-01T22:06:17.324Z',
      current_user: false,
      email: 'scimimported2@hashicorp.com',
      full_name: 'Edwina Scimster',
      id: '17cff5e5-8o88-4275-85fb-b8cb856717fx',
      scim_synchronized: true,
      identity_types: ['HASHICORP_SSO'],
      updated_at: '2020-10-01T22:06:17.324Z',
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org,
      principal: userPrincipalFour,
    });

    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_VIEWER.value)
      .members.push({ member_type: 'USER', member_id: userPrincipalFour.id });

    member_principal_ids = [userPrincipalThree.id, userPrincipalFour.id];

    let userPrincipalFive = server.create('iam.user-principal', {
      created_at: '2023-12-02T22:25:20Z',
      current_user: false,
      email: 'sso_user@sso.com',
      full_name: `${faker.person.firstName()} ${faker.person.lastName()}`,
      id: '5e5ea9a2-db92-43b6-bd7f-f0822a9d7103',
      scim_synchronized: false,
      identity_types: ['HASHICORP_SSO'],
      updated_at: '2023-12-06T18:03:20Z',
    });

    server.create('iam.organization-membership', {
      created_at: '2020-09-01T22:06:17.324Z',
      organization: org,
      principal: userPrincipalFive,
    });

    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_CONTRIBUTOR.value)
      .members.push({ member_type: 'USER', member_id: userPrincipalFive.id });

    member_principal_ids = [...member_principal_ids, userPrincipalFive.id];

    const groupScimSynchronized = server.create('iam.group', {
      display_name: 'My group with scim synchronized',
      resource_name: `iam/organization/${org.id}/group/group-scim-synchronized-with-members-project-admin`,
      resource_id: 'iam.group:6b55wqdGmhwpBb8MRtXx',
      description:
        'Scim synchronized group with members, has admin role in project level IAM policy binding.',
      member_principal_ids,
      scim_synchronized: true,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    });
    projectPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_ADMIN.value)
      .members.push({
        member_type: 'GROUP',
        member_id: groupScimSynchronized.resource_id,
      });
  }

  // For paginated IAM users list
  let paginatedUsers = server.createList('iam.user-principal', 20);

  paginatedUsers.map((user) => {
    server.create('iam.organization-membership', {
      created_at: '2022-04-15T22:06:17.324Z',
      organization: org,
      principal: user,
    });

    // Add USER to iam organizationPolicy dynamically
    organizationPolicy.bindings
      .find((b) => b.role_id === ROLE_TYPE_CONTRIBUTOR.value)
      .members.push({ member_type: 'USER', member_id: user.id });
  });

  let lessPermissions = [
    `${PREFIX_NETWORK_HVNS}.${ACTION_LIST}`,
    `${PREFIX_NETWORK_ROUTES}.${ACTION_LIST}`,
    `${PREFIX_NETWORK_PEERINGS}.${ACTION_LIST}`,
    `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_VAULT_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_LIST}`,
    `${PREFIX_IAM_USERS}.${ACTION_LIST}`,
  ];

  createPermissions(server, {
    organization: org,
    user,
    allowedPermissions: options.allowedPermissions,
  });
  createProjectPermissions(server, {
    organization: org,
    project,
    user,
    allowedPermissions: options.allowedPermissions,
  });
  createPermissions(server, {
    organization: org2,
    user,
    allowedPermissions: lessPermissions,
  });
  createPermissions(server, {
    organization: org3,
    user,
    allowedPermissions: lessPermissions,
  });
  createPermissions(server, {
    organization: org4,
    user,
  });

  server.create('invitation.invitation', {
    accepted_at: null,
    created_at: '2020-07-01T22:29:11.974114Z',
    id: '19cff5e5-8o88-4275-85fb-b8xb856717fx',
    invitee_email: user.email,
    inviterUserPrincipal: userPrincipalTwo,
    organization: org3,
    role: 'roles/admin',
    state: 'PENDING',
    valid_until: '2020-07-01T22:29:11.974114Z', // Valid for ~100 years.
  });

  // Organization IAM policy - adds all iam.user-principals and some iam.service-principals to the policy.
  server.create('resource-manager.organization.policy', {
    organization: org,
    policy: organizationPolicy,
  });

  // Project IAM policy - only adds iam.service-principals to the policy.
  server.create('resource-manager.project.policy', {
    project,
    policy: projectPolicy,
  });

  server.create('consul.version', {
    version: 'v1.14.3',
    status: 'RECOMMENDED',
  });

  server.create('consul.version', {
    version: '1.13.5',
    status: 'AVAILABLE',
  });

  server.create('consul.version', {
    version: '1.12.8',
    status: 'AVAILABLE',
  });

  if (options.createBilling) {
    let billingAccount = server.create(
      'billing-account.billing-account',
      slugIdAttrs({
        id: 'default-account',
        name: 'Default Account',
        organization: org,
        project: [project],
        balance: '20.00',
        status: options.billingAccountStatus || BILLING_ACCOUNT_STATUS.ACTIVE,
        on_demand_status:
          options.billingAccountOnDemandStatus ||
          BILLING_ON_DEMAND_STATUS.UNSET,
        pricing_model: options.pricingModel || PRICING_MODEL.PAYG,
        country: 'CA',
        estimatedRemainingCredits:
          options.pricingModel === PRICING_MODEL.PAYG ? '20.00' : '',
      })
    );

    server.create('billing.contract-activation');

    // the billing system creates a PAYG pricing model transition by default
    // when an organization is created
    server.create('billing-account.transition', {
      billing_account: billingAccount,
    });

    if (!options.recentlyActivated) {
      // creates single project overview for default project
      server.create('billing-account.project-overview', {
        billing_account: billingAccount,
        id: project.id,
      });

      // creates running statement resources
      server.createList(
        'billing-account.statement.project.resource',
        faker.number.int({ min: 0, max: 10 }),
        {
          billing_account: billingAccount,
          projectId: project.id,
        }
      );

      // statements and running statement are created regardless of account type
      server.createList('billing-account.statement', 10, {
        billing_account: billingAccount,
        id: project.id,
      });
    }

    let runningStatement = server.create('billing-account.running-statement', {
      billing_account: billingAccount,
    });

    // onDemandBillingMethodDetails will be present for an On Demand, Flexible
    // Consumption, or Entitlement account if a payment method (credit card)
    // has been added. `onDemandBillingMethodDetails` will not be present for a
    // billing account with a Trial or Incomplete status.
    // https://whimsical.com/billing-account-statuses-2SjVmb6k7Q8rCqu5RPeGyL
    if (options.onDemandBillingMethodDetails) {
      let cardDetails = null;
      if (
        options.billingAccountOnDemandStatus !== BILLING_ON_DEMAND_STATUS.UNSET
      ) {
        cardDetails = options.hasExpiredCreditCard
          ? { brand: 'MASTERCARD', last4: 4242, exp_month: 1, exp_year: 1999 }
          : { brand: 'MASTERCARD', last4: 4242, exp_month: 10, exp_year: 2027 };
      }

      server.create('billing-account.on-demand-billing-method-detail', {
        billing_account: billingAccount,
        billing_method: {
          name: 'My Business',
          email: user.email,
          billing_address: {
            line1: '111 W State St',
            city: 'Black Mountain',
            country: 'US',
            postal_code: '28711',
            state: 'NC',
          },
        },
        card_details: cardDetails,
      });

      billingAccount.update({
        on_demand_status:
          options.billingAccountOnDemandStatus ||
          BILLING_ON_DEMAND_STATUS.ACTIVE,
      });
    }

    // determines estimated remaining credits and
    // running statement total given credit balance
    if (billingAccount.pricing_model === PRICING_MODEL.PAYG) {
      // discount is calculated as
      // billingAccount.balance - runningStatement.total
      let estimatedRemainingCredits = 0;
      let total = billingAccount.balance;
      switch (options.billingRemainingBalance) {
        case 'noBalance':
          // full balance has been used, keep variables as they are
          break;
        case 'lowBalance':
          // all but $10 of balance has been used
          estimatedRemainingCredits = 10;
          total = billingAccount.balance - estimatedRemainingCredits;
          break;
        default:
          // any balance greater than $0 and less than
          // total balance - $10 has been used
          estimatedRemainingCredits = 11;
          total = faker.number.int({
            min: 1,
            max: billingAccount.balance - estimatedRemainingCredits,
          });
      }

      runningStatement.update({ total: total });
      billingAccount.update({
        estimated_remaining_credits: estimatedRemainingCredits.toString(),
      });
    }
  }

  if (options.consulPackages) {
    if (options.consulPackages.development) {
      server.create('billing.package', {
        key: 'hashicorp.consul.development',
        name: '',
        resource_type: 'hashicorp.consul.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.027',
                key: 'extra-small',
                dependencies: [],
              },
            ],
          },
        ],
      });
    }

    if (options.consulPackages.standard) {
      server.create('billing.package', {
        key: 'hashicorp.consul.standard',
        name: '',
        resource_type: 'hashicorp.consul.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.069',
                key: 'small',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '0.1725',
                key: 'medium',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '0.345',
                key: 'large',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.consul.dimension.num_service_instances',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.02',
                key: 'num_service_instances',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'small',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.015',
                key: 'num_service_instances_medium',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'medium',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.0125',
                key: 'num_service_instances_large',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'large',
                  },
                ],
              },
            ],
          },
        ],
      });
    }

    if (options.consulPackages.plus) {
      server.create('billing.package', {
        key: 'hashicorp.consul.plus',
        name: '',
        resource_type: 'hashicorp.consul.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.104',
                key: 'small',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '0.207',
                key: 'medium',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '0.38',
                key: 'large',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.consul.dimension.num_service_instances',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.02',
                key: 'num_service_instances_small',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'small',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.015',
                key: 'num_service_instances_medium',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'medium',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.013',
                key: 'num_service_instances_large',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'large',
                  },
                ],
              },
            ],
          },
        ],
      });
    }

    if (options.consulPackages.premium) {
      server.create('billing.package', {
        key: 'hashicorp.consul.premium',
        name: '',
        resource_type: 'hashicorp.consul.cluster',
        items: [
          {
            key: 'hashicorp.billing.dimension.size',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.104',
                key: 'small',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '0.207',
                key: 'medium',
                dependencies: [],
              },
              {
                unit: 'HOUR',
                unit_price: '0.38',
                key: 'large',
                dependencies: [],
              },
            ],
          },
          {
            key: 'hashicorp.consul.dimension.num_service_instances',
            optional: false,
            options: [
              {
                unit: 'HOUR',
                unit_price: '0.02',
                key: 'num_service_instances_small',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'small',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.015',
                key: 'num_service_instances_medium',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'medium',
                  },
                ],
              },
              {
                unit: 'HOUR',
                unit_price: '0.013',
                key: 'num_service_instances_large',
                dependencies: [
                  {
                    item_key: 'hashicorp.billing.dimension.size',
                    option_key: 'large',
                  },
                ],
              },
            ],
          },
        ],
      });
    }
  }

  if (options.vaultPackages) {
    if (options.vaultPackages.development) {
      createVaultPackages(server, { packageType: 'development' });
    }

    if (options.vaultPackages.starter) {
      createVaultPackages(server, { packageType: 'starter' });
    }

    if (options.vaultPackages.standard) {
      createVaultPackages(server, { packageType: 'standard' });
    }

    if (options.vaultPackages.plus) {
      createVaultPackages(server, { packageType: 'plus' });
    }
  }

  if (options.createNetwork === false) {
    return;
  }
  let network = server.create('network.network', {
    id: 'test-network',
    location: loc,
    cidr_block: '172.20.15.0/20',
    created_at: '2020-10-14T03:16:17.187Z',
    updated_at: '2020-10-14T03:16:17.187Z',
    provider_network_data: {
      aws_network_data: {
        account_id: '123456789012',
      },
    },
  });

  if (options.createAzureNetwork === true) {
    let azureRegion = server.create('location.region', {
      provider: 'azure',
      region: 'eastus',
    });

    let azureLocation = server.create('location.location', {
      organizationId: org.id,
      projectId: project.id,
      regionId: azureRegion.id,
    });

    let azureNetwork = server.create('network.network', {
      id: 'test-azure-network',
      location: azureLocation,
      cidr_block: '172.20.15.0/20',
      created_at: '2020-10-14T03:16:17.187Z',
      updated_at: '2020-10-14T03:16:17.187Z',
      provider_network_data: undefined,
    });

    if (options.createAzurePeering) {
      let azurePeering = server.create('network.peering', {
        id: 'hub-spoke-peering-test',
        hvn: azureNetwork,
        state: options.createAzurePeering.state,
        providerPeeringId: 'pcx-222bbb2222',
        created_at: '2021-01-01T22:06:17.324Z',
      });

      if (options.createAzurePeering.hubSpoke) {
        let azureTarget = server.create('network.peering.azure-target', {
          subscription_id: 'subscription-1234',
          tenant_id: 'tenantid-1234',
          resource_group_name: 'resource-group-name',
          vnet_name: 'vnet-name-1234',
          allow_forwarded_traffic:
            options.createAzurePeering.hubSpoke?.allowForward,
          use_remote_gateways: options.createAzurePeering.hubSpoke?.useGateway,
        });

        let azurePeeringTarget = server.create('network.peering.target', {
          id: 'peering-slug-id-azure-target',
          azureTarget: azureTarget,
          awsTarget: null,
          hvnTarget: null,
        });

        azurePeering.update({ target: azurePeeringTarget });

        if (options.createAzurePeering.azureTarget) {
          server.create('network.route', {
            id: `${azurePeering.id}-route-1`,
            hvn: network,
            state: 'PENDING',
            destination: network.cidr_block,
            target: {
              azure_target: {
                type: TYPE_NETWORK_PEERING,
                id: azurePeering.id,
                location: loc,
                description: azurePeering.description,
              },
            },
          });
        }
      }
    }
  }

  let networkLink = server.schema.findBy('location.link', { id: network.id });

  if (options.createPeeringConnection) {
    let peering = server.create('network.peering', {
      id: 'peering-test',
      hvn: network,
      state: options.createPeeringConnection.state,
      providerPeeringId: 'pcx-111aaa111',
      created_at: '2021-01-01T22:06:17.324Z',
    });

    if (options.createPeeringConnection.hvnRoutes) {
      server.create('network.route', {
        id: `${peering.id}-route-1`,
        hvn: network,
        state: 'PENDING',
        destination: network.cidr_block,
        target: {
          hvn_connection: {
            type: TYPE_NETWORK_PEERING,
            id: peering.id,
            location: loc,
            description: peering.description,
          },
        },
      });

      server.create('network.route', {
        id: `${peering.id}-route-2`,
        hvn: network,
        state: 'ACTIVE',
        destination: '10.0.24.55',
        target: {
          hvn_connection: {
            type: TYPE_NETWORK_PEERING,
            id: peering.id,
            location: loc,
            description: peering.description,
          },
        },
      });
    }
  }

  if (options.createTgwAttachment) {
    let attachment = server.create('network.tgw-attachment', {
      id: 'tgw-test',
      hvn: network,
      state: options.createTgwAttachment.state,
      providerTgwAttachmentId: 'tgw-attach-111aaa111',
      provider_data: {
        aws_data: {
          tgw_id: 'tgw-111aaa111',
          account_id: '1234567890',
        },
      },
      location: {
        organizationId: loc.organizationId,
        projectId: loc.project,
        region: region,
      },
      cidrs: ['172.20.16.0/20', '10.0.0.0/10'],
    });

    server.create('location.link', {
      id: attachment.id,
      type: TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
      description: `HVN Transit Gateway Attachment ("${attachment.id}")`,
      location: loc,
    });
    if (options.createTgwAttachment.hvnRoutes) {
      for (let n = 0; n < attachment.cidrs.length; n++) {
        server.create('network.route', {
          id: `${attachment.id}-route-${n + 1}`,
          hvn: network,
          state: 'PENDING',
          destination: attachment.cidrs[n],
          target: {
            hvn_connection: {
              type: TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
              id: attachment.id,
              location: loc,
              description: attachment.description,
            },
          },
        });
      }
    }
  }

  if (options.createVaultRadar) {
    const scannedSourceLocation = server.create('location.location', {
      organizationId: org.id,
      projectId: project.id,
    });

    server.create('location.link', {
      id: 'vault-radar',
      type: TYPE_VAULT_RADAR,
      location: scannedSourceLocation,
    });
  }

  if (options.createConsul !== false) {
    server.create('consul-global-network-manager.trial', {
      organization_id: loc.organizationId,
      trial_status:
        HashicorpCloudGlobalNetworkManager20220215TrialStatus.INELIGIBLE,
    });
    let consulCluster = server.create('consul.cluster', {
      id: 'test-cluster',
      consul_version: 'v1.8.6',
      created_at: '2020-10-14T02:16:17.187Z',
      resourceId: faker.string.uuid(),
      config: {
        capacity_config: {
          size: 'SMALL',
        },
        network_config: {
          network: networkLink,
          private: true,
          ip_allowlist: [
            { address: '172.25.16.0/24', description: 'address-1' },
            { address: '130.25.16.0/30', description: 'address-2' },
            { address: '124.25.16.0/4', description: 'address-3' },
          ],
        },
        tier: 'STANDARD',
        primary: null,
      },
      dns_names: {
        public: `test-cluster.public.consul.${network.id}.aws.hcp.dev`,
        private: `test-cluster.private.consul.${network.id}.aws.hcp.dev`,
      },
      location: loc,
    });

    if (options.numConsulConnectedClients) {
      server.createList('consul.client', options.numConsulConnectedClients);
    }

    if (options.createConsulAuditLogs) {
      const archivesList = server.schema.findBy('log.archives-list', {
        $resourceId: consulCluster.id,
        $resourceType: 'hashicorp.consul.cluster',
        $locationId: loc.id,
      });

      const processingArchive = server.create('log.archive', {
        $resourceId: consulCluster.id,
        $resourceType: 'hashicorp.consul.cluster',
        $location: loc,
        status: LogService20210330Status.PROCESSING,
      });
      archivesList.archiveIds.push(processingArchive.id);

      const completedArchive = server.create('log.archive', {
        $resourceId: consulCluster.id,
        $resourceType: 'hashicorp.consul.cluster',
        $location: loc,
        status: LogService20210330Status.COMPLETED,
      });
      archivesList.archiveIds.push(completedArchive.id);

      const failedArchive = server.create('log.archive', {
        $resourceId: consulCluster.id,
        $resourceType: 'hashicorp.consul.cluster',
        $location: loc,
        status: LogService20210330Status.FAILED,
      });
      archivesList.archiveIds.push(failedArchive.id);

      archivesList.save();
    }

    if (options.createConsulAuditLogs && options.createConsulPlatformLogs) {
      server.create('log.platformEvent', {
        $resourceId: consulCluster.id,
        $locationId: loc.id,
        topic: 'hashicorp.platform.audit',
        timestamp: faker.date.past().toISOString(),
        control_plane_event: {
          description: 'Created a Consul cluster',
          principal: { user: { full_name: 'Cory Dransfeldt' } },
          action: 'CREATE',
          status_code: 'OK',
        },
      });

      server.create('log.platformEvent', {
        $resourceId: consulCluster.id,
        $locationId: loc.id,
        timestamp: faker.date.past().toISOString(),
        topic: 'hashicorp.platform.audit',
        control_plane_event: {
          description: 'Created a Consul cluster',
          principal: { user: { full_name: 'Jesse Peterson' } },
          action: 'CREATE',
          status_code: 'FAIL',
        },
      });

      server.create('log.platformEvent', {
        $resourceId: consulCluster.id,
        $locationId: loc.id,
        topic: 'pirate.guybrush.threepwood',
        timestamp: faker.date.past().toISOString(),
        control_plane_event: {
          description:
            'Won insult sword-fighting in The Secret of Monkey Island™ by Lucasarts',
          principal: { user: { full_name: 'Guybrush Threepwood' } },
          action: 'UNSET',
          status_code: 'LE_CHUCK',
        },
      });
    }

    if (options.createOrgStreamingDestinations) {
      const REGIONS_LOOKUP = {
        ['cloudwatch']: faker.helpers.arrayElement([
          'Cape Town (af-south-1)',
          'Hong Kong (ap-east-1)',
          'Frankfurt (eu-central-1)',
          'Bahrain (me-south-1)',
          'Sao Paulo (sa-east-1)',
          'N. Virginia (us-east-1)',
        ]),
        ['datadog']: faker.helpers.arrayElement([
          'US1 (https://app.datadoghq.com)',
          'EU1 (https://app.datadoghq.eu)',
          'US1-FED (https://app.ddog-gov.com)',
          'US3 (https://us3.datadoghq.com)',
          'US5 (https://us5.datadoghq.com)',
        ]),
      };

      const ENDPOINTS_LOOKUP = {
        ['cloudwatch']: faker.helpers.arrayElement([
          'https://logs.us-east-1.amazonaws.com',
          'https://logs.af-south-1.amazonaws.com',
          'https://logs.ap-east-1.amazonaws.com',
          'https://logs.eu-central-1.amazonaws.com',
          'https://logs.me-south-1.amazonaws.com',
          'https://logs.sa-east-1.amazonaws.com',
        ]),
        ['datadog']: faker.helpers.arrayElement([
          'US1 (https://app.datadoghq.com)',
          'EU1 (https://app.datadoghq.eu)',
          'US1-FED (https://app.ddog-gov.com)',
          'US3 (https://us3.datadoghq.com)',
          'US5 (https://us5.datadoghq.com)',
        ]),
      };

      const provider = faker.helpers.arrayElement(['cloudwatch', 'datadog']);
      const datadogDestination = {
        id: faker.string.uuid(),
        organization_id: org.id,
        resource: {
          type: TYPE_STREAMING_DESTINATION,
          id: faker.string.uuid(),
          uuid: faker.string.uuid(),
          location: {
            organization_id: org.id,
            project_id: project.id,
            region: {
              provider: 'datadog',
              region: REGIONS_LOOKUP[provider],
            },
          },
        },
        source: 'DESTINATION_SOURCE_HCP_UI',
        endpoint: 'https://logs.us-east-1.datadog.com',
        in_error: faker.datatype.boolean(),
        name: faker.hacker.noun(),
        status: 'pending',
        filter: {},
      };
      const datadogProvider = {
        endpoint: 'https://logs.us-east-1.datadog.com',
        authorization: {
          header: 'Bearer',
          value: '******1bcsdb326JKhkr',
        },
      };

      const splunkCloudDestination = {
        id: faker.string.uuid(),
        organization_id: org.id,
        resource: {
          type: TYPE_STREAMING_DESTINATION,
          id: faker.string.uuid(),
          uuid: faker.string.uuid(),
          location: {
            organization_id: org.id,
            project_id: project.id,
            region: {
              provider: provider,
              region: REGIONS_LOOKUP[provider],
            },
          },
        },
        source: 'DESTINATION_SOURCE_HCP_UI',
        endpoint: 'https://logs.us-east-1.splunk.com',
        splunk_cloud_provider: {
          hec_endpoint: 'https://logs.us-east-1.splunkcloud.com',
          token: '******1bcsdb326JKhkr',
        },
        in_error: faker.datatype.boolean(),
        name: faker.hacker.noun(),
        status: 'error',
        filter: {},
      };
      const splunkCloudProvider = {
        hec_endpoint: 'https://logs.us-east-1.splunkcloud.com',
        token: '******1bcsdb326JKhkr',
      };

      server.create('log.streamingListDestination', {
        organizationId: org.id,
        projectId: project.id,
        destinations: [
          {
            id: faker.string.uuid(),
            organization_id: org.id,
            resource: {
              type: TYPE_STREAMING_DESTINATION,
              id: faker.string.uuid(),
              uuid: faker.string.uuid(),
              location: {
                organization_id: org.id,
                project_id: project.id,
                region: {
                  provider: provider,
                  region: REGIONS_LOOKUP[provider],
                },
              },
            },
            source: 'DESTINATION_SOURCE_TERRAFORM',
            in_error: faker.datatype.boolean(),
            name: faker.hacker.noun(),
            endpoint: 'https://logs.us-east-1.amazonaws.com',
            filter: {},
          },
          datadogDestination,
          splunkCloudDestination,
        ],
      });
      server.create('log.streamingGetDestination', {
        destination: {
          ...splunkCloudDestination,
          splunk_cloud_provider: splunkCloudProvider,
        },
      });
      server.create('log.streamingGetDestination', {
        destination: {
          ...datadogDestination,
          datadog_provider: datadogProvider,
        },
      });
    }

    let selfManagedConsulCluster = server.create(
      'consul-global-network-manager.cluster',
      {
        id: 'self-managed-cluster',
        name: 'self-managed-cluster',
        location: loc,
        licensing:
          HashicorpCloudGlobalNetworkManager20220215Licensing.ENTERPRISE,
      }
    );
    server.create('location.link', {
      id: selfManagedConsulCluster.id,
      type: TYPE_CONSUL_GLOBAL_NETWORK_MANAGER,
      location: loc,
    });
    server.createList('consul-global-network-manager.cluster.server', 3, {
      cluster: selfManagedConsulCluster,
    });

    if (options.consulReadOnlyCluster) {
      server.create('consul-global-network-manager.cluster', {
        id: 'read-only-cluster',
        name: 'read-only-cluster',
        consul_access_level: 'CONSUL_ACCESS_LEVEL_GLOBAL_READ_ONLY',
        state: 'STATE_UPDATING_ACCESS_LEVEL',
        location: loc,
        hcp_managed: false,
      });
    }
  }

  if (options.createVault) {
    if (options.createVault.development) {
      server.create('vault.cluster', 'development', 'template.kvApprole001', {
        location: loc,
        $network_id: network.id,
      });
    }

    if (options.createVault.starter) {
      server.create(
        'vault.cluster',
        'audit-logs.elasticsearch',
        'metrics.elasticsearch',
        'starter',
        {
          location: loc,
          $network_id: network.id,
        }
      );
    }

    if (options.createVault.standard) {
      server.create('vault.cluster', 'standard', {
        location: loc,
        $network_id: network.id,
      });
    }

    if (options.createVault.plus) {
      const primary = server.create('vault.cluster', 'plus', {
        location: loc,
        $network_id: network.id,
      });

      // Create secondary cluster
      // Create aws network with unique cidr
      const secondaryNetwork = server.create('network.network', {
        id: 'Unique network',
        state: 'STABLE',
        cidr_block: '10.12.2.2/20',
        location: loc,
      });

      server.create('vault.cluster', 'asSecondary', {
        id: 'Secondary-Cluster-1',
        $primary_id: primary.id,
        location: loc,
        $network_id: secondaryNetwork.id,
      });
    }
  }

  if (options.consulPrimaryFederatedCluster) {
    server.create('consul.cluster', {
      id: 'primary-cluster',
      consul_version: 'v1.8.6',
      created_at: '2020-10-14T02:16:17.187Z',
      resourceId: faker.string.uuid(),
      config: {
        capacity_config: {
          size: 'LARGE',
        },
        network_config: {
          network: networkLink,
          private: false,
          ip_allowlist: [],
        },
        tier: 'STANDARD',
        primary: {
          type: 'hashicorp.consul.cluster',
          id: 'primary-cluster',
          location: loc,
          uuid: '',
          description: '',
        },
      },
      dns_names: {
        public: `test-cluster.public.consul.primary-cluster.aws.hcp.dev`,
        private: `test-cluster.private.consul.primary-cluster.aws.hcp.dev`,
      },
      location: loc,
    });
  }

  if (options.consulSecondaryFederatedCluster) {
    server.create('consul.cluster', {
      id: 'secondary-cluster',
      consul_version: 'v1.8.6',
      created_at: '2020-10-14T02:16:17.187Z',
      resourceId: faker.string.uuid(),
      config: {
        capacity_config: {
          size: 'LARGE',
        },
        network_config: {
          network: networkLink,
          private: false,
          ip_allowlist: [],
        },
        tier: 'STANDARD',
        primary: {
          type: 'hashicorp.consul.cluster',
          id: 'primary-cluster',
          location: loc,
          uuid: '',
          description: '',
        },
      },
      dns_names: {
        public: `secondary-cluster-1.public.consul.${network.id}.aws.hcp.dev`,
        private: `secondary-cluster-1.private.consul.${network.id}.aws.hcp.dev`,
      },
      location: loc,
    });
  }

  if (options.consulPrimaryDevFederatedCluster) {
    server.create('consul.cluster', {
      id: 'primary-dev-cluster',
      consul_version: 'v1.8.6',
      created_at: '2020-10-14T02:16:17.187Z',
      resourceId: faker.string.uuid(),
      config: {
        capacity_config: {
          size: 'X_SMALL',
        },
        network_config: {
          network: networkLink,
          private: false,
          ip_allowlist: [],
        },
        tier: 'DEVELOPMENT',
        primary: {
          type: 'hashicorp.consul.cluster',
          id: 'primary-dev-cluster',
          location: loc,
          uuid: '',
          description: '',
        },
      },
      dns_names: {
        public: `test-cluster.public.consul.primary-cluster.aws.hcp.dev`,
        private: `test-cluster.private.consul.primary-cluster.aws.hcp.dev`,
      },
      location: loc,
    });
  }

  if (options.consulSecondaryDevFederatedCluster) {
    server.create('consul.cluster', {
      id: 'secondary-dev-cluster',
      consul_version: 'v1.8.6',
      created_at: '2020-10-14T02:16:17.187Z',
      resourceId: faker.string.uuid(),
      config: {
        capacity_config: {
          size: 'X_SMALL',
        },
        network_config: {
          network: networkLink,
          private: false,
          ip_allowlist: [],
        },
        tier: 'DEVELOPMENT',
        primary: {
          type: 'hashicorp.consul.cluster',
          id: 'primary-dev-cluster',
          location: loc,
          uuid: '',
          description: '',
        },
      },
      dns_names: {
        public: `secondary-cluster-1.public.consul.${network.id}.aws.hcp.dev`,
        private: `secondary-cluster-1.private.consul.${network.id}.aws.hcp.dev`,
      },
      location: loc,
    });
  }

  server.create('role.role', {
    id: 'roles/owner',
    roleId: 'roles/owner',
    version: 0,
    title: 'Owner',
    description:
      'Has all of the admin’s permissions, and also the ability to delete the organization and promote/demote other owners.',
    permissions: [
      'network.hvns.update',
      'consul.clusters.get-telemetry',
      'vault.performancereplication.disable',
      'vault.performancereplication.enable',
      'vault.performancereplication.secondaries-list',
      'waypoint.namespaces.get',
      'consul.clusters.create',
      'consul.clusters.delete',
      'resource-manager.organizations.delete',
    ],
  });

  server.create('role.role', {
    id: 'roles/admin',
    roleId: 'roles/admin',
    version: 0,
    title: 'Admin',
    description: 'Has all of the project admin permissions.',
    permissions: [
      'network.hvns.update',
      'consul.clusters.get-telemetry',
      'vault.performancereplication.disable',
      'vault.performancereplication.enable',
      'vault.performancereplication.secondaries-list',
      'waypoint.namespaces.get',
      'consul.clusters.create',
      'consul.clusters.delete',
    ],
  });

  server.create('role.role', {
    id: 'roles/contributor',
    roleId: 'roles/contributor',
    version: 0,
    title: 'Contributor',
    description:
      'Can create and manage all types of resources. Can only view IAM.',
    permissions: [
      'network.hvns.update',
      'consul.clusters.get-telemetry',
      'vault.performancereplication.disable',
      'vault.performancereplication.enable',
      'vault.performancereplication.secondaries-list',
      'waypoint.namespaces.get',
      'consul.clusters.create',
      'consul.clusters.delete',
    ],
  });

  server.create('role.role', {
    id: 'roles/viewer',
    roleId: 'roles/viewer',
    version: 0,
    title: 'Viewer',
    description:
      'Can view existing resources. Cannot create new resources or edit existing ones. Cannot view the org IAM page.',
    permissions: [
      'consul.clusters.get-telemetry',
      'vault.performancereplication.secondaries-list',
      'waypoint.namespaces.get',
    ],
  });

  server.create('role.role', {
    id: ROLE_TYPE_SECRETS_MANAGER.value,
    roleId: ROLE_TYPE_SECRETS_MANAGER.value,
    version: 0,
    title: 'Vault Secrets App Manager',
    description:
      'This role is a collection of permissions that allows principals to create/read/update/delete secrets and add sync mappings inside a Vault Secrets app',
    permissions: [
      'secrets.apps.get',
      'secrets.apps.list',
      'secrets.apps.create',
      'secrets.apps.update',
      'secrets.apps.delete',
      'secrets.app-secrets.create',
      'secrets.app-secrets.update',
      'secrets.app-secrets.delete',
      'secrets.app-secrets.rotate',
      'secrets.app-secrets.open',
      'secrets.app-secret-versions.open',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.rotating-twilio.create',
      'secrets.app-secret-versions.list',
      'secrets.app-secrets-state.get',
      'secrets.sync-integrations.list',
      'secrets.sync-integrations.get',
      'secrets.sync-installations.get',
      'secrets.rotation-integrations.list',
      'secrets.rotation-integrations.read',
    ],
  });

  server.create('role.role', {
    id: ROLE_TYPE_SECRETS_READER.value,
    roleId: ROLE_TYPE_SECRETS_READER.value,
    version: 0,
    title: 'Vault Secrets App Secret Reader',
    description:
      "This role is a collection of permissions that allows principals to read secret values inside an Vault Secrets' app",
    permissions: [
      'secrets.apps.get',
      'secrets.apps.list',
      'secrets.app-secrets.get',
      'secrets.app-secrets.list',
      'secrets.app-secrets.open',
      'secrets.app-secret-versions.list',
      'secrets.app-secrets-state.get',
    ],
  });

  if (options.hasNoActiveSessions) {
    server.createList('session-manager.session', 0);
  } else {
    server.createList('session-manager.session', 5);
  }

  // setup billing for HVS
  server.create('secrets.billing.account-tier', {
    billingTier: options.hvsBillingTier ?? 'FREE',
    orgId: org.id,
  });
}
