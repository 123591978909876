/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { Billing20201105GetRunningStatementResponseFromJSON, Billing20201105GetStatementCSVResponseFromJSON, Billing20201105GetStatementResponseFromJSON, Billing20201105GetStripeInvoiceResponseFromJSON, Billing20201105ListRunningStatementProjectOverviewsResponseFromJSON, Billing20201105ListRunningStatementResourcesByProjectResponseFromJSON, Billing20201105ListStatementProjectOverviewsResponseFromJSON, Billing20201105ListStatementResourcesByProjectResponseFromJSON, Billing20201105ListStatementsResponseFromJSON, } from '../models';
/**
 *
 */
export class StatementServiceApi extends runtime.BaseAPI {
    /**
     * GetRunningStatement returns a billing account\'s running statement.
     */
    async statementServiceGetRunningStatementRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceGetRunningStatement.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceGetRunningStatement.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/running-statement`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetRunningStatementResponseFromJSON(jsonValue));
    }
    /**
     * GetRunningStatement returns a billing account\'s running statement.
     */
    async statementServiceGetRunningStatement(organizationId, billingAccountId) {
        const response = await this.statementServiceGetRunningStatementRaw({ organizationId: organizationId, billingAccountId: billingAccountId });
        return await response.value();
    }
    /**
     * GetStatement returns a billing account\'s monthly statement by ID.
     */
    async statementServiceGetStatementRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceGetStatement.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceGetStatement.');
        }
        if (requestParameters.statementId === null || requestParameters.statementId === undefined) {
            throw new runtime.RequiredError('statementId', 'Required parameter requestParameters.statementId was null or undefined when calling statementServiceGetStatement.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/statements/{statement_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"statement_id"}}`, encodeURIComponent(String(requestParameters.statementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetStatementResponseFromJSON(jsonValue));
    }
    /**
     * GetStatement returns a billing account\'s monthly statement by ID.
     */
    async statementServiceGetStatement(organizationId, billingAccountId, statementId) {
        const response = await this.statementServiceGetStatementRaw({ organizationId: organizationId, billingAccountId: billingAccountId, statementId: statementId });
        return await response.value();
    }
    /**
     */
    async statementServiceGetStatementCSVRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceGetStatementCSV.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceGetStatementCSV.');
        }
        if (requestParameters.statementId === null || requestParameters.statementId === undefined) {
            throw new runtime.RequiredError('statementId', 'Required parameter requestParameters.statementId was null or undefined when calling statementServiceGetStatementCSV.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/statements/{statement_id}/csv`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"statement_id"}}`, encodeURIComponent(String(requestParameters.statementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetStatementCSVResponseFromJSON(jsonValue));
    }
    /**
     */
    async statementServiceGetStatementCSV(organizationId, billingAccountId, statementId) {
        const response = await this.statementServiceGetStatementCSVRaw({ organizationId: organizationId, billingAccountId: billingAccountId, statementId: statementId });
        return await response.value();
    }
    /**
     * GetStripeInvoice gets information about the Stripe invoice (if one exists) for a given statement.
     */
    async statementServiceGetStripeInvoiceRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceGetStripeInvoice.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceGetStripeInvoice.');
        }
        if (requestParameters.statementId === null || requestParameters.statementId === undefined) {
            throw new runtime.RequiredError('statementId', 'Required parameter requestParameters.statementId was null or undefined when calling statementServiceGetStripeInvoice.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/statements/{statement_id}/invoice`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"statement_id"}}`, encodeURIComponent(String(requestParameters.statementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetStripeInvoiceResponseFromJSON(jsonValue));
    }
    /**
     * GetStripeInvoice gets information about the Stripe invoice (if one exists) for a given statement.
     */
    async statementServiceGetStripeInvoice(organizationId, billingAccountId, statementId) {
        const response = await this.statementServiceGetStripeInvoiceRaw({ organizationId: organizationId, billingAccountId: billingAccountId, statementId: statementId });
        return await response.value();
    }
    /**
     * ListRunningStatementProjectOverviews returns a filterable list of summarized project and TFC org usage for an organization\'s billing account\'s running statement.
     */
    async statementServiceListRunningStatementProjectOverviewsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceListRunningStatementProjectOverviews.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceListRunningStatementProjectOverviews.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/running-statement/projects`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListRunningStatementProjectOverviewsResponseFromJSON(jsonValue));
    }
    /**
     * ListRunningStatementProjectOverviews returns a filterable list of summarized project and TFC org usage for an organization\'s billing account\'s running statement.
     */
    async statementServiceListRunningStatementProjectOverviews(organizationId, billingAccountId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.statementServiceListRunningStatementProjectOverviewsRaw({ organizationId: organizationId, billingAccountId: billingAccountId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListRunningStatementResourcesByProject returns a filterable list of resources for the running statement and a specified project.
     */
    async statementServiceListRunningStatementResourcesByProjectRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceListRunningStatementResourcesByProject.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceListRunningStatementResourcesByProject.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling statementServiceListRunningStatementResourcesByProject.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/running-statement/projects/{project_id}/resources`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListRunningStatementResourcesByProjectResponseFromJSON(jsonValue));
    }
    /**
     * ListRunningStatementResourcesByProject returns a filterable list of resources for the running statement and a specified project.
     */
    async statementServiceListRunningStatementResourcesByProject(organizationId, billingAccountId, projectId) {
        const response = await this.statementServiceListRunningStatementResourcesByProjectRaw({ organizationId: organizationId, billingAccountId: billingAccountId, projectId: projectId });
        return await response.value();
    }
    /**
     * ListStatementProjectOverviews returns a filterable list of summarized project and TFC org usage for a specified statement and project.
     */
    async statementServiceListStatementProjectOverviewsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceListStatementProjectOverviews.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceListStatementProjectOverviews.');
        }
        if (requestParameters.statementId === null || requestParameters.statementId === undefined) {
            throw new runtime.RequiredError('statementId', 'Required parameter requestParameters.statementId was null or undefined when calling statementServiceListStatementProjectOverviews.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/statements/{statement_id}/projects`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"statement_id"}}`, encodeURIComponent(String(requestParameters.statementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListStatementProjectOverviewsResponseFromJSON(jsonValue));
    }
    /**
     * ListStatementProjectOverviews returns a filterable list of summarized project and TFC org usage for a specified statement and project.
     */
    async statementServiceListStatementProjectOverviews(organizationId, billingAccountId, statementId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.statementServiceListStatementProjectOverviewsRaw({ organizationId: organizationId, billingAccountId: billingAccountId, statementId: statementId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListStatementResourcesByProject returns a filterable list of resources for a specified statement and project.
     */
    async statementServiceListStatementResourcesByProjectRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceListStatementResourcesByProject.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceListStatementResourcesByProject.');
        }
        if (requestParameters.statementId === null || requestParameters.statementId === undefined) {
            throw new runtime.RequiredError('statementId', 'Required parameter requestParameters.statementId was null or undefined when calling statementServiceListStatementResourcesByProject.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling statementServiceListStatementResourcesByProject.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/statements/{statement_id}/projects/{project_id}/resources`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"statement_id"}}`, encodeURIComponent(String(requestParameters.statementId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListStatementResourcesByProjectResponseFromJSON(jsonValue));
    }
    /**
     * ListStatementResourcesByProject returns a filterable list of resources for a specified statement and project.
     */
    async statementServiceListStatementResourcesByProject(organizationId, billingAccountId, statementId, projectId) {
        const response = await this.statementServiceListStatementResourcesByProjectRaw({ organizationId: organizationId, billingAccountId: billingAccountId, statementId: statementId, projectId: projectId });
        return await response.value();
    }
    /**
     * ListStatements returns a billing account\'s current and historical monthly statements.
     */
    async statementServiceListStatementsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling statementServiceListStatements.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling statementServiceListStatements.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/statements`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListStatementsResponseFromJSON(jsonValue));
    }
    /**
     * ListStatements returns a billing account\'s current and historical monthly statements.
     */
    async statementServiceListStatements(organizationId, billingAccountId) {
        const response = await this.statementServiceListStatementsRaw({ organizationId: organizationId, billingAccountId: billingAccountId });
        return await response.value();
    }
}
