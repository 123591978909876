import { faker } from '@faker-js/faker';
import userScenario from './user';
import { fullPermissions } from '../helpers/permissions';
import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from 'billing-common/constants/status';

const supportedVcsEvents = [
  'viewed',
  'listed',
  'created',
  'updated',
  'deleted',
];

export default function (server, options = {}) {
  userScenario(server, {
    // billing
    createBilling: true,
    hasVerifiedEmail: true,
    billingAccountStatus: BILLING_ACCOUNT_STATUS.ACTIVE,
    onDemandBillingMethodDetails: true,
    billingAccountOnDemandStatus: BILLING_ON_DEMAND_STATUS.ACTIVE,

    // misc
    createConsul: false,
    createVault: false,
    allowedPermissions: fullPermissions,
    hvsBillingTier: 'FREE',
    ...options,
  });

  const firstOrg = server.schema.first('resourceManager.organization');
  const firstProj = server.schema.first('resourceManager.project');

  // create sync integrations
  const commonSyncIntegrationProps = {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    location: { organization_id: firstOrg.id, project_id: firstProj.id },
  };

  server.create('secrets.sync-installation', {
    $orgId: firstOrg.id,
    $projId: firstProj.id,
    name: 'default',
  });

  const syncIntegrations = [];

  syncIntegrations.push(
    server.create('secrets.sync-integration', 'aws-sm', {
      name: 'primary-aws-sync',
      ...commonSyncIntegrationProps,
    })
  );

  syncIntegrations.push(
    server.create('secrets.sync-integration', 'gh-repo', {
      name: 'gh-repo-test-gh-org-fish',
      gh_repo_connection_details: {
        owner: 'test-gh-org',
        environment: 'production',
        repository: 'fish',
      },
      ...commonSyncIntegrationProps,
    })
  );

  syncIntegrations.push(
    server.create('secrets.sync-integration', 'gh-org', {
      name: faker.string.uuid(),
      gh_org_connection_details: {
        org: 'test-gh-org',
        visibility: 'selected',
        selected_repository_names: ['bananas', 'carrots', 'daikon'],
      },
      ...commonSyncIntegrationProps,
    })
  );

  syncIntegrations.push(
    server.create('secrets.sync-integration', 'vercel-project', {
      name: 'vercel-test-project',
      ...commonSyncIntegrationProps,
    })
  );

  syncIntegrations.push(
    server.create('secrets.sync-integration', 'azure-kv', {
      name: 'primary-azure-sync',
      ...commonSyncIntegrationProps,
    })
  );

  // create more aws integrations to test pagination on the details route
  syncIntegrations.push(
    ...server.createList(
      'secrets.sync-integration',
      6,
      'aws-sm',
      commonSyncIntegrationProps
    )
  );
  // create more vercel integrations to test pagination on old explicit type routes (can be removed once routes are deprecated)
  syncIntegrations.push(
    ...server.createList(
      'secrets.sync-integration',
      6,
      'vercel-project',
      commonSyncIntegrationProps
    )
  );

  // create rotation integrations
  const twilioIntegration = server.create('secrets.integration', 'twilio', {
    name: 'twilio-rotating',
  });

  const mongoIntegration = server.create(
    'secrets.integration',
    'mongodb-atlas',
    {
      name: 'mongodb-atlas-rotating',
    }
  );

  // create dynamic integrations
  server.create('secrets.integration', 'aws', {
    name: 'dynamic-secrets-aws',
  });

  server.create('secrets.integration', 'gcp', {
    name: 'dynamic-secrets-gcp',
  });

  server.createList('secrets.app', 7).forEach((app, i) => {
    const { id, location, name } = app;

    switch (name) {
      // app-0 will have syncs
      case 'app-0':
        app.update({
          sync_integrations: syncIntegrations.map((i) => i.name),
        });

        server
          .createList(
            'secrets.app-secret',
            Math.floor(Math.random() * 10) + 3,
            'static.kv',
            {
              app,
            }
          )
          .forEach((secret) => {
            // each secret needs to have a status for each integration
            syncIntegrations.forEach((integration) => {
              server.create('secrets.app-secret.sync-event', {
                status:
                  integration.name === 'primary-aws-sync'
                    ? 'IN_ERROR'
                    : 'SYNCED',
                last_error_code: 'IntegrationUnauthorized',
                updated_at: Date.now(),

                secret,
                syncIntegration: integration,

                $appName: name,
                $syncName: integration.name,
                $orgId: location.organization_id,
                $projId: location.project_id,
              });
            });
          });

        break;

      // app-1 will have rotating secrets
      case 'app-1':
        server.create('secrets.app-secret', 'rotating.twilio', {
          app,
          name: 'APP_1_TWILIO_STAGING',
          integration_name: twilioIntegration.name,
          $rotationPolicyName: 'built-in:90-days-2-active',
        });

        server.create('secrets.app-secret', 'rotating.mongodb-atlas', {
          app,
          name: `APP_1_DB_PROD`,
          integration_name: mongoIntegration.name,
          $rotationPolicyName: 'built-in:90-days-2-active',
        });

        // add an empty secret for the sake of testing
        // eslint-disable-next-line no-case-declarations
        const emptySecret = server.create('secrets.app-secret', 'empty', {
          app,
          name: 'empty_rotating_secret',
          $status: 'ROTATION_STARTING',
        });

        setTimeout(() => {
          emptySecret.update({ $status: 'ERRORED' });
        }, 10000);

        // create some extra kv secrets so the page looks nicer
        server.createList('secrets.app-secret', 1, 'static.kv', { app });
        break;
      //app-2 will have dynamic secrets
      case 'app-2':
        server.create('secrets.app-secret', {
          app,
          name: 'DYNAMIC_SECRET_1',
          provider: 'aws',
          type: 'dynamic',
          integration_name: 'dynamic-secrets-aws',
          defaultTtl: '3600s',
          assume_role: {
            role_arn: 'arn:aws:iam::123456789012:role/role-name',
          },
          dynamic_instance: {
            ttl: '3600s',
            values: {
              access_key_id: 'ASIAW3JCYODXE4TNSWMD',
              secret_access_key: 'EXAMPLESECRETACCESSKEY',
              session_token: faker.string.alphanumeric(748),
            },
          },
        });

        server.create('secrets.app-secret', {
          app,
          name: 'DYNAMIC_SECRET_2',
          provider: 'gcp',
          type: 'dynamic',
          integration_name: 'dynamic-secrets-gcp',
          defaultTtl: '3600s',
          service_account_impersonation: {
            service_account_email:
              'a-service-account@my-gcp-project.iam.gserviceaccount.com',
            token_scopes: [
              'https://www.googleapis.com/auth/cloud-platform.read-only',
              'https://www.googleapis.com/auth/cloud-platform.write-only',
            ],
          },
          dynamic_instance: {
            created_at: faker.date.past({ hours: 2 }).toISOString(),
            ttl: '3600s',
            values: {
              access_token: faker.string.uuid(),
            },
          },
        });

        break;
      case 'app-3':
        // app 3 will not have any secrets or other resources
        // this is intentional as we can test the "zero day" states
        break;
      default:
        server.createList(
          'secrets.app-secret',
          Math.floor(Math.random() * 10) + 3,
          'static.kv',
          {
            app,
          }
        );
    }

    for (const e of supportedVcsEvents) {
      server.createList('log.platform-event', 3, 'secret', `secrets.${e}`, {
        stream: {
          organization_id: location.organization_id,
          project_id: location.project_id,
          topic: 'hashicorp.platform.audit',
        },
        control_plane_event: {
          metadata: {
            app_name: name,
            app_id: id,
          },
        },
      });
    }
  });
}
