import flexScenario from './billing-flex';
import getPlatformContext from '../helpers/get-platform-context';

export default function (server) {
  flexScenario(server);

  let { organization, project } = getPlatformContext(server);

  let billingAccount = server.schema.findBy('billing-account.billing-account', {
    organizationId: organization.id,
    projectIds: [project.id],
  });

  let runningStatement = server.schema.findBy(
    'billing-account.running-statement',
    {
      billingAccountId: billingAccount.id,
    }
  );

  runningStatement.update({
    total: '0.00',
    resources: [],
  });
}
