import flexScenario from './billing-flex';

let options = {
  contracts: [
    { traits: ['expired', 'zeroBalance'] },
    { traits: ['recentlyActivated'] },
  ],
};

export default function (server) {
  flexScenario(server, options);
}
