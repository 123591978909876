import unifiedExperienceScenario from './unified-experience';
import getPlatformContext from '../helpers/get-platform-context';

import {
  TYPE_TERRAFORM_STACK,
  TYPE_TERRAFORM_WORKSPACE,
} from 'common/utils/cloud-resource-types';
import { TFC_GEO_REGIONS } from 'core/utils/constants';
import { buildResourceId } from '../helpers/resource-manager/v2-resources';

let options = {
  createBilling: true,
};

export default function (server) {
  unifiedExperienceScenario(server, { ...options, projectCount: 1 });

  const { organization, project } = getPlatformContext(server);

  const region = server.schema.findBy('location.region', { provider: 'aws' });

  const location = server.schema.findOrCreateBy('location.location', {
    organizationId: organization.id,
    projectId: project.id,
    regionId: region.id,
  });

  server.schema
    .where('location.link', (link) => {
      return location.id === link.locationId;
    })
    .destroy();

  const resources = [
    {
      id: 'tfc-workspace-us',
      type: TYPE_TERRAFORM_WORKSPACE,
      geo: TFC_GEO_REGIONS.REGION_US,
    },
    {
      id: 'tfc-workspace-eu',
      type: TYPE_TERRAFORM_WORKSPACE,
      geo: TFC_GEO_REGIONS.REGION_EU,
    },
    {
      id: 'tfc-stack-us',
      type: TYPE_TERRAFORM_STACK,
      geo: TFC_GEO_REGIONS.REGION_US,
    },
  ];

  resources.forEach(({ id, type, geo }) => {
    server.schema.findOrCreateBy('location.link', {
      id,
      type,
      location,
    });

    server.create('resource-manager.resource', {
      resource_name: id,
      resource_id: buildResourceId(type, id),
      geo,
    });
  });
}
