import secretsStandardTierScenario from './secrets.standard-tier';

export default function (server, options = {}) {
  secretsStandardTierScenario(server, {
    hvsBillingTier: 'PLUS',
    ...options,
  });

  server.create('secrets.integration', 'aws', {
    name: 'aws-rotation-integration',
    capabilities: ['ROTATION'],
  });

  server.create('secrets.integration', 'gcp', {
    name: 'gcp-rotation-integration',
    capabilities: ['ROTATION'],
  });
}
