/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Vault Radar
 * API for managing Vault Radar.
 *
 * The version of the OpenAPI document: 2023-05-01
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationFromJSON, Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationToJSON, } from './';
export function InlineObject3FromJSON(json) {
    return InlineObject3FromJSONTyped(json, false);
}
export function InlineObject3FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationFromJSON(json['location']),
    };
}
export function InlineObject3ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationToJSON(value.location),
    };
}
