import flexScenario from './billing-flex';

let options = {
  contracts: [{ traits: ['activatedToday'] }],
  recentlyActivated: true,
};

export default function (server) {
  flexScenario(server, options);
}
