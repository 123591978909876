import { faker } from '@faker-js/faker';
import { isTesting } from '@embroider/macros';

import { HashicorpCloudWaypointTerraformTFRunState } from '@clients/cloud-waypoint-service';

/**
 * @typedef { import("miragejs").Server } MirageServer
 * @typedef { import("miragejs").Request } MirageRequest
 * @typedef { import("../models/waypoint.build").default } MirageBuild
 * @typedef { import("../models/waypoint.deployment").default } MirageDeployment
 * @typedef { import("../models/waypoint.release").default } MirageRelease
 * @typedef { import("@clients/cloud-waypoint-service").HashicorpWaypointBuild } Build
 * @typedef { import("@clients/cloud-waypoint-service").HashicorpWaypointDeployment } Deployment
 * @typedef { import("@clients/cloud-waypoint-service").HashicorpWaypointRelease } Release
 * @typedef { import("@clients/cloud-waypoint-service").HashicorpWaypointRefWorkspace } WorkspaceRef
 * @typedef { import("@clients/cloud-waypoint-service").HashicorpWaypointRefAddOnDefinition } AddOnDefinitionRef
 *
 * @typedef {{
 *  'waypoint.namespace': typeof import("../models/waypoint.namespace").default,
 *  'waypoint.tf-run-state': typeof import("../models/waypoint.tf-run-state").default,
 *  'waypoint.tfc-config': typeof import("../models/waypoint.tfc-config").default,
 *  'waypoint.tfc-token': typeof import("../models/waypoint.tfc-token").default,
 *  'waypoint.tfc-organization': typeof import("../models/waypoint.tfc-organization").default,
 *  'waypoint.input-variable': typeof import("../models/waypoint.input-variable").default,
 *  'waypoint.output-value': typeof import("../models/waypoint.output-value").default,
 *  'waypoint.application': typeof import("../models/waypoint.application").default,
 *  'waypoint.application-template': typeof import("../models/waypoint.application-template").default,
 *  'waypoint.add-on': typeof import("../models/waypoint.add-on").default,
 *  'waypoint.add-on-definition': typeof import("../models/waypoint.add-on-definition").default,
 *  'waypoint.tf-module': typeof import("../models/waypoint.tf-module").default
 *  'waypoint.tf-module-detail': typeof import("../models/waypoint.tf-module-detail").default
 *  'waypoint.tf-module-variable': typeof import("../models/waypoint.tf-module-variable").default
 * }} MirageModels
 * @typedef {{
 * 'waypoint.namespace': typeof import("../factories/waypoint.namespace").default,
 * 'waypoint.tf-run-state': import("../factories/waypoint.tf-run-state").MirageTfRunStateFactory,
 * 'waypoint.tfc-config': import("../factories/waypoint.tfc-config").MirageTfcConfigFactory,
 * 'waypoint.tfc-token': import("../factories/waypoint.tfc-token").MirageTfcTokenFactory,
 * 'waypoint.tfc-organization': typeof import("../factories/waypoint.tfc-organization").default,
 * 'waypoint.add-on-definition': import("../factories/waypoint.add-on-definition").MirageAddOnDefinitionFactory
 * }} MirageFactories
 * @typedef { import("miragejs").Registry<MirageModels, MirageFactories> } MirageRegistry
 * @typedef { import("miragejs/orm/schema").default<MirageRegistry> } MirageSchema
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.tf-run-state"} MirageTfRunStateInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.input-variable"} MirageInputVariableInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.output-value"} MirageOutputValueInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.application"} MirageAppInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.application-template"} MirageApplicationTemplateInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.add-on-definition"} MirageAddOnDefinitionInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.add-on"} MirageAddOnInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.tfc-config"} MirageTfcConfigInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.tfc-token"} MirageTfcTokenInstance
 * @typedef { import("miragejs").Instantiate<MirageRegistry, "waypoint.namespace"} MirageNamespaceInstance
 */
/**
 * @template {string} Y
 * @typedef {string extends Y ? string : Y extends `${infer C0}${infer R}` ? `${C0 extends Lowercase<C0> ? "" : "_"}${Lowercase<C0>}${CamelToSnake<R>}` : "" } CamelToSnake
 */
/**
 * @template T
 * @typedef {{ [K in keyof T as CamelToSnake<Extract<K, string>>]: T[K] }} CamelKeysToSnake
 */

/**
 * If the given operation has no workspace, associate it with the default
 * workspace in the operation’s namespace.
 *
 * @param {MirageBuild | MirageDeployment | MirageRelease} operation
 * @param {MirageServer} server
 */
export function assignDefaultWorkspace(operation, server) {
  if (operation.workspace) {
    return;
  }

  let namespace = operation.application?.project?.namespace;

  if (!namespace) {
    return;
  }

  let workspace = server.schema.findOrCreateBy('waypoint.workspace', {
    namespaceId: namespace.id,
    name: 'default',
  });

  operation.update({ workspace });
}

/**
 * Convert Mirage full model fields into refs used in the actual API
 * @template P
 * @template {keyof P} K
 * @template {NonNullable<P[K]>} Y
 *
 * @param { P } serializedModel The model after Mirage serializes it (should be a HashicorpWaypoint* type)
 * @param { K } keyToPopulateRef The key of the full class on the serialized model that needs to be replaced with a ref
 * @param { Y } ref The ref object
 *
 * @returns { P }
 */
export function populateRef(serializedModel, keyToPopulateRef, ref) {
  // eslint-disable-next-line no-undef
  const clonedModel = structuredClone(serializedModel);
  clonedModel[keyToPopulateRef] = ref;
  return clonedModel;
}

/**
 * Convenience function to generate a workspace ref object to pass to populateRef
 * @param {string} workspaceName
 * @returns {WorkspaceRef}
 */
export function createWorkspaceRef(workspaceName) {
  return { workspace: workspaceName };
}

/**
 * Convenience function to generate an add-on definition ref object to pass to populateRef
 * @param {string} addOnDefinitionName
 * @returns {AddOnDefinitionRef}
 */
export function createAddOnDefinitionRef(addOnDefinitionName) {
  return { name: addOnDefinitionName };
}

/**
 * Convenience function to create an associated TerraformTFRunState model and update its state
 * @param {MirageSchema} schema
 * @param {string} namespaceId
 * @param {(MirageAppInstance | MirageAddOnInstance)} owner The name of the app/add-on to associate this status with
 * @param {keyof typeof HashicorpCloudWaypointTerraformTFRunState} [finalState] Sets the final state. This is overwritten by if a trait is specified when being called from a factory
 * @param {number} [delayMs] An optional delay where the state will be RUNNING before being updated to finalState
 * @param {(status: finalState,...args: unknown[]) => unknown} [callback] An optional function to call after the delay
 * @returns {MirageTfRunStateInstance}
 */
export function createOrUpdateTFRunState(
  schema,
  namespaceId,
  owner,
  finalState,
  delayMs = 0,
  callback
) {
  const tfRunState =
    owner.tfRunState ||
    schema.create('waypoint.tf-run-state', {
      namespaceId,
      owner,
    });

  const tfcConfig = schema.findBy('waypoint.tfc-config', { namespaceId });
  const organizationName = tfcConfig?.tfcOrganization.name;
  const workspaceName = tfRunState?.name;
  const url = `https://app.terraform.io/app/${organizationName}/workspaces/${workspaceName}`;
  tfRunState.update({ url });

  const onFinalState = () => {
    tfRunState.update({ state: finalState });
    callback && callback(finalState);
  };

  if (isTesting()) {
    console.info('running without timeout in test environment');
    onFinalState();
  } else {
    tfRunState.update({
      state: HashicorpCloudWaypointTerraformTFRunState.RUNNING,
    });
    setTimeout(onFinalState, delayMs);
  }
  return tfRunState;
}

/**
 * Wrapper function that provides the Mirage server to a route handler.
 * TODO: move to general utilities if other route handlers end up using this
 *
 * @param {(schema: MirageSchema, request: MirageRequest) => unknown} routeHandler
 * @param {MirageServer} server
 */
export function routeHandlerWithServer(routeHandler, server) {
  return function (...args) {
    return routeHandler.call(this, ...args, server);
  };
}

/**
 * Convenience function to create "set" TF module variables (only have one option) for an application template or add-on definition, based on the variables contained in a tf module detail
 * @param {MirageServer} [server]
 * @param {(MirageApplicationTemplateInstance | MirageAddOnDefinitionInstance)} owner The tf-module-detail/add-on-definition/application-template Mirage instance to associate TF module variables with
 * @returns {MirageInputVariableInstance[]}
 */
export function createSetTFModuleVariables(server, owner) {
  if (isTesting() || !server) {
    console.info(
      "Did not create TF module variables (you're in a test environment or server is undefined). In tests, create TF module variables manually via schema.create(), otherwise wrap your route handler with the routeHandlerWithServer helper"
    );
    return [];
  }
  const tfModule = findTfModule(server.schema, owner);
  const variables = tfModule.tfModuleDetails.variables;
  const setVariables = variables.models.map((variable) => {
    const {
      id: _id,
      ownerId: _ownerId,
      variableType,
      options,
      userEditable,
      ...newAttrs
    } = variable.attrs;

    const selectedOption = options.length
      ? faker.helpers.arrayElement(options)
      : generateVariableOptionValue(variableType);

    return server.create('waypoint.tf-module-variable', {
      owner,
      variableType,
      options: [selectedOption],
      ...newAttrs,
    });
  });

  return setVariables;
}

/**
 * Convenience function to create HCP-created input variables
 * @param {MirageSchema} schema
 * @param {string} namespaceId
 * @param {(MirageAppInstance | MirageAddOnInstance)} owner The app/add-on Mirage instance to associate input variables with
 * @returns {void}
 */
export function createInputVariablesSetByHCP(schema, namespaceId, owner) {
  let name;
  switch (owner.modelName) {
    case 'waypoint.application':
      name = 'waypoint_application';
      break;
    case 'waypoint.add-on-definition':
      name = 'waypoint_add_on_definition';
      break;
    case 'waypoint.add-on':
      name = 'waypoint_add_on';
      break;
    default:
  }

  schema.create('waypoint.input-variable', {
    owner,
    namespaceId,
    name,
    value: owner.name,
    variableType: 'string',
  });
}

/**
 * Convenience function to create input variables
 * @param {MirageServer} [server]
 * @param {string} namespaceId
 * @param {(MirageAppInstance | MirageAddOnInstance)} owner The app/add-on Mirage instance to associate input variables with
 * @returns {MirageInputVariableInstance[]}
 */
export function createInputVariables(server, namespaceId, owner) {
  if (isTesting() || !server) {
    console.info(
      "Did not create input variables (you're in a test environment or server is undefined). In tests, create input variables manually via schema.create(), otherwise wrap your route handler with the routeHandlerWithServer helper"
    );
    return [];
  }
  const inputVariables = Array(faker.number.int({ min: 0, max: 5 }))
    .fill()
    .map(() =>
      server.create('waypoint.input-variable', {
        namespaceId,
        owner,
      })
    );
  return inputVariables;
}

/**
 * Convenience function to create output variables
 * @param {MirageServer} [server]
 * @param {string} namespaceId
 * @param {(MirageAppInstance | MirageAddOnInstance)} owner The app/add-on Mirage instance to associate output values with
 * @returns {MirageOutputValueInstance[]}
 */
export function createOutputValues(server, namespaceId, owner) {
  if (isTesting() || !server) {
    console.info(
      "Did not create output values (you're in a test environment or server is undefined). In tests, create output values manually via schema.create(), otherwise wrap your route handler with the routeHandlerWithServer helper"
    );
    return [];
  }
  const outputValuesCount = faker.number.int({ min: 0, max: 5 });
  const outputValues = Array(outputValuesCount)
    .fill()
    .map(() =>
      server.create(
        'waypoint.output-value',
        faker.datatype.boolean(0.25) ? 'isSensitive' : '',
        {
          namespaceId,
          owner,
        }
      )
    );
  return outputValues;
}

/**
 * JSON-encodes the given object, then encodes the JSON in the format Waypoint’s
 * API expects for a `bytes` field.
 *
 * @param {any} obj
 * @returns {string} encoded bytes
 */
export function encodeJSONBytes(obj) {
  return encodeUTF8Bytes(encodeJSON(obj));
}

/**
 * JSON-encodes the given object.
 *
 * @param {any} obj
 * @returns {string} JSON-encoded string
 */
export function encodeJSON(obj) {
  return JSON.stringify(obj, null, 2);
}

/**
 * Encodes the given string as UTF-8 data for storage in a `bytes` field.
 *
 * This uses the technique for base64-encoding UTF-8 strings described here:
 * https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
 *
 * @param {string} str - input data, as a string
 * @returns {string} bytes encoded for the wire, as a string
 */
export function encodeUTF8Bytes(str) {
  const utf8Bytes = new TextEncoder().encode(str);
  const utf8BytesAsString = Array.prototype.map
    .call(utf8Bytes, (b) => String.fromCharCode(b))
    .join('');
  const result = btoa(utf8BytesAsString);

  return result;
}

/**
 * Decodes the given `bytes` as UTF-8 data.
 *
 * @param {string} encoded - base64-encoded UTF-8 data
 * @returns {string} decoded text
 */
export function decodeUTF8Bytes(encoded) {
  const decoded = atob(encoded);
  const bytes = Uint8Array.from(decoded.split('').map((c) => c.charCodeAt(0)));
  const result = new TextDecoder().decode(bytes);

  return result;
}

/**
 * Generates a Terraform no-code module source string.
 * Format is: "private/${org}/${module}/${provider}"
 *
 * See: waypoint/utils/terraform-no-code-module.ts
 *
 * @param {string} org - org name
 * @param {string} module - module name
 * @param {string} provider - provider name
 * @returns {string} Terraform no-code module source string
 */
export function createTerraformNocodeModuleSource(org, module, provider) {
  return `private/${org}/${module}/${provider}`;
}

/**
 * Prases a Terraform no-code module source string and returns an object containing org name, module name, and provider name.
 *
 * See: waypoint/utils/terraform-no-code-module.ts
 *
 * @param {string} terraformNocodeModuleSource no-code module source string
 * @returns {{ org: string, module: string, provider: string }} Parsed Terraform no-code module
 */
export function parseTerraformNocodeModuleSource(
  terraformNocodeModuleSource = ''
) {
  const [, org = '', module = '', provider = ''] =
    terraformNocodeModuleSource.split('/');
  return {
    org,
    module,
    provider,
  };
}

/**
 * Generates a random add-on name in a manner approximating the server.
 * @param {string} appName
 * @param {string} addOnDefinitionName
 * @return {string}
 */
export function generateAddOnName(appName, addOnDefinitionName) {
  return `${appName}-${addOnDefinitionName}-${randomSuffix()}`;
}

/**
 * Helper object to assist in registering afterCreate methods in the Mirage addOn factory
 * @typedef { keyof typeof HashicorpCloudWaypointTerraformTFRunState } TFRunStates
 * @typedef { `will-${TFRunStates}`  } TFWillRunStates
 * @typedef { {[K in TFWillRunStates]: K} } TFWillRunStatesEnum
 */
/**
 * @type TFWillRunStatesEnum
 */
export const TFWillRunStatesEnum = {};
for (const status in HashicorpCloudWaypointTerraformTFRunState) {
  TFWillRunStatesEnum[`will-${status}`] = `will-${status}`;
}

const WAYPOINT_FAKER_SESSION_STORAGE_KEY = 'waypoint-default-scenario';
const DEFAULT_WAYPOINT_FAKER_SEED = 123;

export function getFakerSeed() {
  return +sessionStorage.getItem(WAYPOINT_FAKER_SESSION_STORAGE_KEY);
}

export function setFakerSeed(candidateSeed = DEFAULT_WAYPOINT_FAKER_SEED) {
  const seed = +candidateSeed;
  sessionStorage.setItem(WAYPOINT_FAKER_SESSION_STORAGE_KEY, seed);
  return seed;
}

/**
 * Generates random Markdown by composing various Markdown generator functions.
 *
 * @param {number} sections How many sections of Markdown to generate
 * @returns {string} Generated Markdown
 */
export function createMarkdown(
  sections = faker.helpers.rangeToNumber({ min: 3, max: 8 })
) {
  let maxHeader = 1;
  const markdown = [...Array(sections).keys()].reduce((currentMarkdown) => {
    // randomly pick a Markdown generator function from markdownStructures
    const [markdownType, markdownGenerator] =
      faker.helpers.objectEntry(markdownStructures);
    let generatedMarkdown = '';
    if (markdownType === 'header') {
      // special case to handle headers - Axe rules require headings to be in valid logical order and not increase by more than 1 level
      const { generatedHeader, maxHeader: newMaxHeader } =
        markdownGenerator(maxHeader);
      maxHeader = newMaxHeader;
      generatedMarkdown = `${generatedHeader}\n`;
    } else {
      generatedMarkdown = `${markdownGenerator()}\n`;
    }
    // append generated Markdown and return
    return currentMarkdown + generatedMarkdown;
  }, '' /* initial markdown */);
  return markdown;
}

/**
 * Generates a Markdown header - Only generates to adhere to Axe rule (can't increase by more than 1 header level)
 *
 * @param {number} maxHeader The current max header level - Generated header level can only be maxHeader or maxHeader + 1
 * @param {number} userHeaderLevel A user-provided header level. Has no effect if > maxHeader + 1 and an Axe rule adhering level header will be generated instead
 * @returns {string} Generated Markdown header
 */
const header = (
  maxHeader = 1,
  userHeaderLevel = faker.helpers.rangeToNumber({ min: 1, max: 6 })
) => {
  let headerLevel = userHeaderLevel;
  if (
    maxHeader > userHeaderLevel || // user supplied header level is higher than allowed (i.e. using an h1 after an h2)
    Math.abs(userHeaderLevel - maxHeader) > 1 // user supplied header level is more than 1 level than previous
  ) {
    const newMax = Math.min(maxHeader + 1, 6);
    headerLevel = faker.helpers.rangeToNumber({
      min: maxHeader,
      max: newMax,
    });
  }
  const generatedHeader = `${'#'.repeat(
    headerLevel
  )} ${faker.internet.domainWord()}`;
  return {
    maxHeader: headerLevel,
    generatedHeader,
  };
};

/**
 * @param {string} text
 * @returns {string} Italicized Markdown
 */
const italicize = (text) => {
  return `*${text}*`;
};
/**
 * @param {string} text
 * @returns {string} Bolded Markdown
 */
const bold = (text) => {
  return `**${text}**`;
};
/**
 * @param {string} text
 * @param {string} url
 * @returns {string} A Markdown link
 */
const link = (text, url = 'https://www.hashicorp.com') => {
  return `[${text}](${url})`;
};
/**
 * @param {string} text
 * @returns {string} Indented Markdown
 */
const indent = (text = faker.lorem.words()) => {
  return `\n> ${text}\n\n`;
};
const wordMods = [italicize, bold, indent, link];

/**
 * @param {number} sentenceCount The number of sentences in the paragraph
 * @returns {string} Markdown paragraph with wordMods applied
 */
const paragraph = (sentenceCount) => {
  const paragraph = faker.lorem.paragraph(sentenceCount);
  const splitParagraph = paragraph.split(' ');
  const wordIndex = faker.helpers.rangeToNumber({
    min: 0,
    max: splitParagraph.length - 1,
  });
  splitParagraph[wordIndex] = faker.helpers.arrayElement(wordMods)(
    splitParagraph[wordIndex]
  );
  return splitParagraph.join(' ');
};

/**
 * @returns {string} Randomly selected code block from codeSamples
 */
const codeSample = () => {
  return `\`\`\`\n${faker.helpers.arrayElement(codeSamples)}\n\`\`\``;
};

const codeSamples = [
  `if (flood * 1) {
        token.left_cd += videoCopyrightLanguage * fat;
        leak(interactive_zone + 91);
    } else {
        heap_bespoke += 3 * 101 + -2;
    }
    if (activexZone(export)) {
        search(8, seoClick);
        del += phreaking(-2 + 3);
        plugComputerSoftware(panel);
    } else {
        multi = 4;
    }
    mountain.pop(-5, 1);`,
  `pptp_jsp.office_gibibyte = cron_cd_ddl * -5;
    bing = 4;
    if (mailBridge == driveIeeeBackbone) {
        overwrite = vleBalancingRefresh(2, bareBounceTcp, 3);
    }
    windows -= gifDrive;`,
  `addressKerningPage /= 17;
    var donationware_open_subnet =
            kilobit_template_desktop.analogCycleWorkstation(
            computer_multicasting.browser(analogApplicationOperation,
            telecommunicationsLcd, stackDvi), 1) + dock_webmail;
    webmaster(tutorial.memory_component_iscsi(3, 767156, -3), market);`,
  `cardSrgb = protector_copy(fullSystemCard(remote_gigabyte,
            freeware.drive_lion.yahoo(hard)), dropRecursivePeopleware(end) *
            output_radcab, point_acl_payload);
    if (horse_text_payload.mbpsGoogle(5, 1) >= username) {
        screenshotTable += balancing_cold(pim,
                wizardCommercial.captcha_antivirus(5, websiteDfs, boot),
                leaderboardMatrix);
    } else {
        zip_drive.error_ugc(memory, heat_p, 5);
    }`,
  `if (umlDcimLaser.cdnKey(shortcut_external_processor + kernelInternicWeb -
            emoticonTransistor)) {
        soft += pppoe_wpa_import(botnet, type + pitch_cookie_buffer, 61 -
                interfaceWarm);
        disk_data_whitelist.rateFacebookWeb += system_commercial_irq;
    }
    if (sdramSupercomputerRam.app_bit(bootModule, solid, media_ddr_mbps.bootWeb(
            web, -1))) {
        google_footer = clone_osd;
        session_directx_web = visual.name.plagiarismDriveAgp(qwerty, mysqlSd +
                software, kernelFont);
        scareware_cloud = 4;
    } else {
        e.dpi_listserv = 2;
        drag_file = password_smtp;
    }`,
  `if (gui_dvd(2, bandwidth) != teraflops_vector_file + cdStreaming) {
        baseband = copy(full_trojan_megahertz);
        postscript_pda_digital += 24 / modeBar - flat;
    } else {
        vlog.cpa_program(gigahertzExportIndex + 4, barCamera);
        boolean.input_uml += ripcording;
        metaTypeHardware(714015);
    }`,
];

/**
 * @param {number} listLength How many items in the list
 * @param {('bullet' | 'numbered')} listStyle Bulleted or numbered list
 *
 * @returns {string} Markdown list
 */
const list = (
  listLength = 3,
  listStyle = faker.helpers.arrayElement(['bullet', 'numbered'])
) => {
  let listString = '';
  if (listStyle === 'bullet') {
    for (let i = 0; i < listLength; i++) {
      listString += `- ${faker.lorem.words()}\n`;
    }
  }
  if (listStyle === 'numbered') {
    for (let i = 0; i < listLength; i++) {
      listString += `${i + 1}. ${faker.lorem.words()}\n`;
    }
  }
  listString += '<br />';
  return listString;
};

const markdownStructures = {
  header,
  codeSample,
  list,
  paragraph,
};

/**
 * Generates a random sequence of characters in manner approximating Kubernetes.
 * @param {number} length
 * @returns {string}
 */
export function randomSuffix(length = 5) {
  const alphabet = 'bcdfghjklmnpqrstvwxz2456789';
  const chosen = new Array(length).fill();

  for (const i in chosen) {
    chosen[i] = alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  return chosen.join('');
}

/**
 * Generates a semantic version "MAJOR.MINOR.PATCH"
 *
 * @returns a semantic version {string}
 */
export function generateSemanticVersion() {
  return `${faker.number.int({ min: 0, max: 20 })}.${faker.number.int({
    min: 0,
    max: 9,
  })}.${faker.number.int({ min: 0, max: 9 })}`;
}

/**
 * Compares semantic versions
 *
 * @param {string} a semantic version "MAJOR.MINOR.PATCH"
 * @param {string} b semantic version "MAJOR.MINOR.PATCH"
 * @returns number {number}
 * A negative value indicates that a should come before b.
 * A positive value indicates that a should come after b.
 * Zero or NaN indicates that a and b are considered equal.
 */
export function compareSemanticVersions(a, b) {
  // 1. Split the strings into their parts.
  const a1 = a.split('.');
  const b1 = b.split('.');
  // 2. Contingency in case there's a 4th or 5th version
  const len = Math.min(a1.length, b1.length);
  // 3. Look through each version number and compare.
  for (let i = 0; i < len; i++) {
    const a2 = +a1[i] || 0;
    const b2 = +b1[i] || 0;

    if (a2 !== b2) {
      return a2 > b2 ? 1 : -1;
    }
  }
  // 4. We hit this if the all checked versions so far are equal
  //
  return b1.length - a1.length;
}

/**
 * Generate a variable option value depending on the given variableType
 * @param {'string' | 'number' | 'bool'} variableType
 *
 * @returns { string | number | boolean | undefined }
 */
export function generateVariableOptionValue(variableType) {
  switch (variableType) {
    case 'string':
      return faker.lorem.word();
    case 'number':
      return String(faker.number.int());
    case 'bool':
      return faker.datatype.boolean();
    default:
      return;
  }
}

export const TOKEN_REDACT_LENGTH = 24;
/**
 * server implementation:
 * https://github.com/hashicorp/cloud-waypoint-service/pull/1165
 */
export function redactToken() {
  return '*'.repeat(TOKEN_REDACT_LENGTH);
}

/**
 * @param {string} token
 * server implementation:
 * https://github.com/hashicorp/cloud-waypoint-service/blob/fad34e018433874c8d65b6876fd721aa152ee9ad/service/public/20230818/ptypes/tfc_config.go#L153
 */
export function isRedactedTfcToken(token) {
  return token === '*'.repeat(TOKEN_REDACT_LENGTH);
}

/**
 * @param {MirageTfcConfigInstance} tfcConfig
 */
export function validateTfcConfig(tfcConfig) {
  return !!(
    tfcConfig?.token?.value?.length && tfcConfig?.tfcOrganization?.name?.length
  );
}

/**
 * @param {MirageTfcTokenInstance | null} tfcToken
 */
export function validateTfcToken(tfcToken) {
  return !!(
    tfcToken?.value?.length &&
    tfcToken?.organizations?.length && // TODO: verify this line is still relevant
    new Date() < tfcToken?.expiredAt
  );
}

/**
 * Looks up the correct TF module version for a given template/add-on, taking
 * version pin into account;
 */
export function findTfModule(schema, owner) {
  if (owner.terraformNocodeModule) {
    return owner.terraformNocodeModule;
  }

  // Find any version of the module
  const mod = schema.findBy('waypoint.tf-module', {
    source: owner.moduleSource,
  });
  // Get the associated no-code definition to determine the version pin
  const nocode = mod.nocodeModuleDefinition;

  return schema.findBy('waypoint.tf-module', {
    source: owner.moduleSource,
    version: nocode.versionPin,
  });
}

/**
 * @param {MirageNamespaceInstance} namespace
 */
export function activateNamespace(namespace) {
  namespace.active = true;
  namespace.hasActivated = true;
  return true;
}

/**
 * @param {MirageNamespaceInstance} namespace
 */
export function deactivateNamespace(namespace) {
  namespace.active = false;
  return true;
}
