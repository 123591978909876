import { camelize } from '@ember/string';

import userScenario from './user';

export default function (server) {
  userScenario(server, {
    createBilling: true,
    hasVerifiedEmail: true,
  });

  const organization = server.schema.findBy('resource-manager.organization', {
    name: 'default-org',
  });

  const project = server.schema.findBy('resource-manager.project', {
    parent: { type: 'ORGANIZATION', id: organization.id },
  });

  const namespace = server.create('waypoint.namespace', 'emptyState', {
    organization,
    project,
  });

  // TFC Org and Token
  const tfcToken = server.create('waypoint.tfc-token');
  // This is intentional — we emit the token into the console for use in manual testing in Mirage mode.
  console.info('Simulated TFC token:', tfcToken.value);

  const expiredToken = server.create('waypoint.tfc-token', 'expired', {
    value: '123',
  });
  console.info('Expired TFC token:', expiredToken.value);

  const expiringToken = server.create('waypoint.tfc-token', 'will-expire', {
    value: '1234',
  });
  console.info('Expiring TFC token (in 20 seconds):', expiringToken.value);

  const insufficientPermissionsToken = server.create('waypoint.tfc-token', {
    value: '321',
  });
  console.info(
    'Insufficient permissions TFC token:',
    insufficientPermissionsToken.value
  );

  const willLosePermissionsToken = server.create('waypoint.tfc-token', {
    value: '4321',
  });
  console.info(
    'Will lose permissions (in 20 seconds) TFC token:',
    willLosePermissionsToken.value
  );

  const tfcOrganization = server.create('waypoint.tfc-organization', {
    name: 'acmecorp',
    tokens: [tfcToken, expiredToken],
  });
  server.create('waypoint.tfc-organization', {
    name: 'betacorp',
    tokens: [tfcToken],
  });
  server.create('waypoint.tfc-organization', 'invalid', {
    name: 'c-corp',
    tokens: [insufficientPermissionsToken],
  });
  server.create('waypoint.tfc-organization', 'will-invalid', {
    name: 'losing-permissions-corp',
    tokens: [willLosePermissionsToken],
  });
  server.create('waypoint.tfc-organization', {
    name: 'e-corp',
    tokens: [expiredToken, expiringToken],
  });

  const terraformProjects = {
    default: server.create('waypoint.tfc-project', {
      namespace,
      projectId: 'prj-123456',
      name: 'Default Project',
    }),
    alternate: server.create('waypoint.tfc-project', {
      namespace,
      projectId: 'prj-789101',
      name: 'Alternate Project',
    }),
    newest: server.create('waypoint.tfc-project', {
      namespace,
      projectId: 'prj-654321',
      name: 'Newest Project',
    }),
  };

  const nocodeModuleDefinitions = {
    catBlog: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'cat-blog',
      tfcOrganization,
      versions: ['1.0.0', '0.0.3', '0.0.1'],
    }),
    goAndPostgres: server.create(
      'waypoint.nocode-module-definition',
      'random',
      {
        namespace,
        created: new Date(),
        lastUpdated: new Date(),
        name: 'go-and-postgres',
        tfcOrganization,
        versions: ['0.0.2', '0.0.1'],
      }
    ),
    rubyOnRails: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'ruby-on-rails',
      tfcOrganization,
      versions: ['0.0.5', '0.0.3', '0.0.2', '0.0.1'],
    }),
    nodeJs: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'node-js',
      tfcOrganization,
      versions: ['0.0.5'],
    }),
    redis: server.create('waypoint.nocode-module-definition', 'random', {
      namespace,
      created: new Date(),
      lastUpdated: new Date(),
      name: 'redis',
      tfcOrganization,
      versions: ['1.0.0'],
    }),
  };

  const tfModules = Object.values(nocodeModuleDefinitions).reduce(
    (tfModules, nocodeModuleDefinition) => {
      const { versions } = nocodeModuleDefinition;
      const terraformNocodeModules = versions.map((version) =>
        server.create('waypoint.tf-module', {
          nocodeModuleDefinition,
          version,
        })
      );
      const key = camelize(nocodeModuleDefinition.name);
      tfModules[key] = terraformNocodeModules;
      return tfModules;
    },
    {}
  );
}
