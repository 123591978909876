import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { supportTicketPrefillHref } from '../../../utils/support-ticket.js';
import { variation } from 'ember-launch-darkly';
import { isTesting } from '@embroider/macros';

import type UserContextService from '../../../services/user-context.ts';
import type { IntlService } from 'ember-intl';

interface LayoutSidebarMainNavSignature {
  // Args: {};
}

/**
 *
 * `Layout::SideBar::MainNav` wraps sidebar navigation components.
 *
 *
 * ```
 * <Layout::SideBar::MainNav>{{outlet}}</Layout::SideBar::MainNav>
 * ```
 *
 * @class Layout::SideBar::MainNav
 *
 */

export default class LayoutSidebarMainNavComponent extends Component<LayoutSidebarMainNavSignature> {
  @service declare readonly userContext: UserContextService;
  @service('resources.project') declare readonly projects: unknown;
  @service declare readonly intl: IntlService;

  get vaultRadarUrl() {
    const { project } = this.userContext;
    const vaultRadarUrl = variation('hcp-ui-vault-scanning-integration');
    return `${vaultRadarUrl}/projects/${project?.id}`;
  }

  get vaultRadarBadge() {
    return variation('hcp-vault-radar-public-beta')
      ? this.intl.t('nav.project.radar-badge')
      : null;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  get shouldShowNav() {
    const { organization, project } = this.userContext;
    return organization?.id && project?.id;
  }

  get isTesting() {
    return isTesting();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Sidebar::MainNav': typeof LayoutSidebarMainNavComponent;
    'layout/sidebar/main-nav': typeof LayoutSidebarMainNavComponent;
  }
}
